import React, { useEffect, useContext, createContext, useState } from "react";
import { Button, Row, Col, Table } from "react-bootstrap";
import { BiFolder } from "react-icons/bi";
import { TiPlus } from "react-icons/ti";
import { MdCancel } from "react-icons/md";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

//Helpers
import { getFarmerById, getLogs, getLogsByPagination, deleteFile } from "../../../../helpers/api";

//Shared
import { LoadingOverlay } from "../../../components/shared";

//Types
import { ProjectDataType, DeleteFileType } from "../../../ts/types";

//Upload
import UploadDocument from "./UploadDocument";
import { useUserInfoContext } from "../../../context/UserInfoContext";
import { useProjectDetailsContext } from "../../../context/ProjectDetailsContext";

const initialDeleteData: DeleteFileType = {
  id: "",
  documentType: "",
};

const RefreshContext = createContext(() => {});
export const useRefreshAll = () => {
  return useContext(RefreshContext);
};

const FilesAndLog = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { projectId } = useParams() as any;
  const [userInfo, setUserInfo] = useState(false as unknown as ProjectDataType);
  const [deleteData, setDeleteData] = useState<DeleteFileType>(initialDeleteData);
  const [userLog, setUserLog] = useState([]);
  const [users, setUsers] = useState("");
  const [refresh, setRefresh] = useState(new Date());
  const [uploading, setUploading] = useState<boolean>(false);
  const [afterDelete, setAfterDelete] = useState(new Date());
  const {projectDetails, setProjectDetails} = useProjectDetailsContext()

  const { user }: any = useUserInfoContext();

  useEffect(() => {

    getLogs(projectId).then((res: any) => {
      setUserLog(res.data.data);
      setUsers(res.data.count);
    });
  }, [ refresh, afterDelete]);

  const refreshAll = () => {
    setRefresh(new Date());
  };

  const setNewDate = (unixTime: any) => {
    const date = new Date(parseInt(unixTime));
    return date.toLocaleDateString("en-US");
  };

  const setNewTime = (unixTime: any) => {
    const date = new Date(parseInt(unixTime));
    const dates =
      date.toLocaleTimeString("en-US").slice(0, 4) + "" + date.toLocaleTimeString("en-US").slice(7);
    return dates;
  };

  const deleteSelectedDocument = (documentType: string) => {
    deleteData.documentType = documentType;
    deleteData.id = projectId.toString();
    setUploading(true);

    deleteFile(deleteData).then((res: any) => {
      setAfterDelete(new Date());
      setUploading(false);
      toast.success("File deleted successfully");
    });
  };

  //pagination

  const userPerPage = 10;

  const fetchPagination = (currentPage: string) => {
    setUploading(true);
    getLogsByPagination(currentPage, projectId)
      .then((res: any) => {
        setUserLog(res.data.data);
      })
      .finally(() => setUploading(false));
  };

  const pageCount = Math.ceil(parseInt(users, 10) / userPerPage);
  const changePage = ({ selected }: any) => {
    if (selected === 0) {
      let currentPage = selected;
      fetchPagination(currentPage);
    } else {
      let currentPage = selected * 10;

      fetchPagination(currentPage.toString());
    }
  };

  return (
    <div>
      {user?.role === "SUPERADMIN" && (
        <>
          {uploading && <LoadingOverlay />}

          <hr
            style={{
              backgroundColor: "silver",
              border: "1px silver solid",
              margin: "3rem 0",
            }}
          />
          <p
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              color: "silver",
            }}
            id="Documents"
          >
            Project Document and Files
          </p>
          <Row className="folder_Row">
            {!!projectDetails?.citizenshipUrl && (
              <Col
                xl={3}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="uploaded_files p-0 p-sm-0 space_folder"
                style={{ position: "relative" }}
              >
                <Button
                  onClick={() => {
                    window.open(`${projectDetails?.citizenshipUrl}`);
                  }}
                  variant="outline-secondary"
                  className="folder-btn"
                  style={{
                    fontSize: "0.8rem",
                    height: "65px",
                    width: "270px",
                    marginRight: "15px",
                  }}
                >
                  <Row>
                    <Col xl={4} lg={4} md={4} xs={12} className="folder_shift">
                      <BiFolder size={45} color="silver" />
                    </Col>
                    <Col xl={8} lg={8} md={4} xs={12}>
                      <p className="folder_contents">
                        <b>Citizenship</b> <br />
                        ACIM0001256478.pdf
                        <br />
                        0.89 MB
                      </p>
                    </Col>
                  </Row>
                </Button>

                <Button
                  variant="none"
                  size="sm"
                  onClick={() => deleteSelectedDocument("citizenshipUrl")}
                  className="delete-btn p-0"
                >
                  <MdCancel size="24px" style={{ color: "red" }} />
                </Button>
              </Col>
            )}
            {!!projectDetails?.landOwnershipUrl && (
              <Col
                xl={3}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="  uploaded_files"
                style={{ position: "relative" }}
              >
                <a
                  className="folder-btn"
                  href={projectDetails?.landOwnershipUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outline-secondary"
                    style={{ fontSize: "0.8rem", height: "65px", width: "270px" }}
                  >
                    <Row>
                      <Col xl={3} lg={3} md={3} xs={12}>
                        <BiFolder size={45} color="silver" />
                      </Col>
                      <Col xl={9} lg={9} md={9} xs={12}>
                        <p className="folder_contents">
                          <b>Land Ownership Document</b>
                          <br />
                          ACIM0001256478.pdf
                          <br />
                          0.89 MB
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </a>

                <Button
                  variant="none"
                  size="sm"
                  onClick={() => deleteSelectedDocument("landOwnershipUrl")}
                  className="delete-btn"
                >
                  <MdCancel size="24px" style={{ color: "red" }} />
                </Button>
              </Col>
            )}
            {!!projectDetails?.profileImageUrl && (
              <Col
                xl={3}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className=" uploaded_files"
                style={{ position: "relative" }}
              >
                <a
                  className="folder-btn"
                  href={projectDetails?.profileImageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outline-secondary"
                    style={{ fontSize: "0.8rem", height: "65px", width: "270px" }}
                  >
                    <Row>
                      <Col xl={3} lg={3} md={3} xs={12}>
                        <BiFolder size={45} color="silver" />
                      </Col>
                      <Col xl={9} lg={9} md={9} xs={12}>
                        <p className="folder_contents">
                          {" "}
                          <b>Profile Image</b>
                          <br />
                          DCIM0001256478.jpeg
                          <br />
                          0.89 MB
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </a>
                <Button
                  variant="none"
                  size="sm"
                  onClick={() => deleteSelectedDocument("profileImageUrl")}
                  className="delete-btn"
                >
                  <MdCancel size="24px" style={{ color: "red" }} />
                </Button>
              </Col>
            )}
          </Row>

          <Row>
            {!!projectDetails?.agreementDocUrl && (
              <Col
                xl={3}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="p-0 m-xs-0 p-xs-0 uploaded_files space_folder"
                style={{ position: "relative" }}
              >
                <a
                  className="folder-btn"
                  href={projectDetails?.agreementDocUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outline-secondary"
                    style={{ fontSize: "0.8rem", height: "65px", width: "270px" }}
                  >
                    <Row className="folder_Row">
                      <Col xl={4} lg={4} md={4} xs={12} className="folder_shift">
                        <BiFolder size={45} color="silver" />
                      </Col>
                      <Col xl={8} lg={8} md={8} xs={12}>
                        <p className="folder_contents">
                          {" "}
                          <b>Agreement Document</b>
                          <br />
                          ACIM0001256478.pdf
                          <br />
                          0.89 MB
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </a>
                <Button
                  variant="none"
                  size="sm"
                  onClick={() => deleteSelectedDocument("agreementDocUrl")}
                  className="delete-btn"
                >
                  <MdCancel size="24px" style={{ color: "red" }} />
                </Button>
              </Col>
            )}
            {!!projectDetails?.workOrderDocUrl && (
              <Col
                xl={3}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className=" uploaded_files"
                style={{ position: "relative" }}
              >
                <a
                  className="folder-btn"
                  href={projectDetails?.workOrderDocUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outline-secondary"
                    style={{ fontSize: "0.8rem", height: "65px", width: "270px" }}
                  >
                    <Row>
                      <Col xl={3} lg={3} md={3} xs={12}>
                        <BiFolder size={45} color="silver" />
                      </Col>
                      <Col xl={9} lg={9} md={9} xs={12}>
                        <p className="folder_contents">
                          <b>WorkOrder Document</b>
                          <br />
                          ACIMs01256478.pdf
                          <br />
                          0.89 MB
                        </p>
                      </Col>
                    </Row>
                  </Button>
                </a>
                <Button
                  variant="none"
                  size="sm"
                  onClick={() => deleteSelectedDocument("workOrder")}
                  className="delete-btn"
                >
                  <MdCancel size="24px" style={{ color: "red" }} />
                </Button>
              </Col>
            )}
          </Row>

          <br />
          <Button
            className={"mt-3"}
            variant="outline-secondary"
            style={{
              fontSize: "0.9rem",
              height: "65px",
              width: "270px",
              borderStyle: "dashed",
            }}
            onClick={() => setModalShow(true)}
          >
            <Row className=" upload_Button">
              <Col xl={3} lg={3} md={3} xs={12}>
                <TiPlus size={45} color="silver" style={{ marginLeft: "8px" }} />
              </Col>
              <Col xl={6} lg={6} md={9} xs={12} className="m-0 ">
                Upload Document
              </Col>
            </Row>
          </Button>
          <RefreshContext.Provider value={refreshAll}>
            {" "}
            <UploadDocument show={modalShow} onHide={() => setModalShow(false)} />
          </RefreshContext.Provider>
        </>
      )}

      <hr
        style={{
          backgroundColor: "silver",
          border: "1px silver solid",
          margin: "3rem 0",
        }}
      />

      {/* Logs */}
      <p
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          color: "silver",
        }}
        id="logs"
      >
        Logs
      </p>
      <p
        style={{
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      >
        Milestons in the {projectDetails?.beneficiaryName} Project
      </p>

      <Table style={{ marginBottom: "5rem" }}>
        <thead className="farmerDetail__thead">
          <tr
            style={{
              borderBottom: "1px solid silver",
              fontWeight: "normal",
            }}
          >
            <th>Date</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody className="farmerDetail__tbody">
          {userLog.map((logs: any, index) => {
            if (logs.event === "CREATE_PROJECT") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.description} {""}
                    {setNewTime(logs.NPTTimestamp)} by {logs.name}
                  </td>
                </tr>
              );
            } else if (logs.event === "FILE_UPLOAD") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.description} {logs.name} at {""}
                    {setNewTime(logs.NPTTimestamp)}
                  </td>
                </tr>
              );
            } else if (logs.event === "FILE_DELETE") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.description} {logs.name} at {""}
                    {setNewTime(logs.NPTTimestamp)}
                  </td>
                </tr>
              );
            } else if (logs.event === "UPDATE_PROJECT_STAGE") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.description} {""} at {""}
                    {setNewTime(logs.NPTTimestamp)}
                  </td>
                </tr>
              );
            } else if (logs.event === "UPDATE_PROJECT") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.name} {""}
                    {logs.description} {""}
                    {setNewTime(logs.NPTTimestamp)}
                  </td>
                </tr>
              );
            } else if (logs.event === "GENERAL_INFORMATION_UPDATE") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.name}
                    {logs.description} {""}
                    {setNewTime(logs.NPTTimestamp)}
                  </td>
                </tr>
              );
            } else if (logs.event === "CREATE_METER") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.name} {""}
                    {logs.description}
                    {setNewTime(logs.NPTTimestamp)}
                  </td>
                </tr>
              );
            } else if (logs.event === "UPDATE_METER") {
              const newDate = setNewDate(logs.NPTTimestamp);
              return (
                <tr key={index}>
                  <td>{newDate}</td>
                  <td>
                    {logs.name} {""}
                    {logs.description}
                    {setNewTime(logs.NPTTimestamp)}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={pageCount}
        onPageChange={changePage}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        // previousLinkClassName={"page-link"}
        containerClassName={"pagination justify-content-center"}
        // previousClassName={"page-item"}
        // nextLinkClassName={"page-link"}
        // nextClassName={"page-item"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"active activePagination"}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        breakLabel={"..."}
        breakClassName={"break-label"}
      />
    </div>
  );
};

export default FilesAndLog;
