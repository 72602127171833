import React, { useState, useContext } from "react";
import { projectDetailsProps } from "../views/projectStatus/ts/types";

// Define the context shape
interface ProjectDetailsContextType {
  projectDetails: projectDetailsProps | null;
  setProjectDetails: React.Dispatch<React.SetStateAction<projectDetailsProps | null>>;
}

// Initialize the context with a default value of undefined
const ProjectDetailsContext = React.createContext<ProjectDetailsContextType | undefined>(undefined);

// Custom hook to use the ProjectDetailsContext
export const useProjectDetailsContext = () => {
  const context = useContext(ProjectDetailsContext);
  if (!context) {
    throw new Error("useProjectDetailsContext must be used within a ProjectDetailsInfoProvider");
  }
  return context;
};

const ProjectDetailsInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const [projectDetails, setProjectDetails] = useState<projectDetailsProps | null>(null);

  return (
    <ProjectDetailsContext.Provider value={{ projectDetails, setProjectDetails }}>
      {children}
    </ProjectDetailsContext.Provider>
  );
};

export default ProjectDetailsInfoProvider;
