import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Row,
  Col,
  ToggleButton,
  ToggleButtonGroup,
  ButtonGroup,
  Form,
  Spinner,
  Popover,
  Overlay,
  Modal,
} from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { Download } from "react-bootstrap-icons";
import { FaThermometerEmpty } from "react-icons/fa";
import { WiHumidity } from "react-icons/wi";

// History
import history from "../../../history";
// Assests
import ECSensorTemp from "../../../assets/icons/ecSensorTemp.svg";
import ECSensorHumidity from "../../../assets/icons/eCSensorHumidity.svg";
import ECSensorMoisture from "../../../assets/icons/eCSensorMoisture.svg";
import ECConductivity from "../../../assets/icons/eCConductivity.svg";

// new imports for images
import Humidity from "../../../assets/icons/humidity.svg";
import Temperature from "../../../assets/icons/temperature.svg";
import Carbondioxide from "../../../assets/icons/carbondioxide.svg";
import TotalRuntime from "../../../assets/icons/total-runtime.svg";
import TotalDischarge from "../../../assets/icons/total-discharge.svg";
import RedDot from "../../../assets/icons/red-dot.gif";

import {
  meterDetailsDaily,
  fetchMeterDetailByProjectId,
  getMonthlyDetails,
  getMeterActivityStatus,
  getFarmerById,
  getYearlyDetails,
  enablePump,
  disablePump,
  downloadCsvReport,
  getMeterFilterById,
  fetchRecentOnsiteParams,
  getRecommendationsForCrops,
} from "../../../helpers/api";
import { MeterFilterProps } from "../../ts/types";
import { ChartTypes, FilterByFrequency, SystemParams, EnvironmentalParams } from "../../ts/enums";

import Chart, { TooltipLabelTypes } from "./components/Chart";

import {
  formatTimeDuration,
  getCurrentGmtTime,
  makeDayListForMonth,
  months,
  shortMonths,
} from "../../../helpers/dateTime";
import { GraphDataResponseProps } from "../../ts/responseDataTypes";
import { resetHorizontalFluid, setHorizontalFluid, useLayout } from "../../context/LayoutContext";
import { LoadingOverlay } from "../../components/shared";
import { toast } from "react-toastify";
import Weather from "./Weather";
import { useUserInfoContext } from "../../context/UserInfoContext";

const initialfilterOptions: MeterFilterProps = {
  filter: EnvironmentalParams.TEMPERATURE,
  meterId: "",
  from: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
  to: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
  frequency: FilterByFrequency.DAILY,
};
type SystemParamsOptionProps = {
  name: string;
  value: SystemParams | EnvironmentalParams;
};

type MeterStatusProps = {
  currentMeterStatus: string;
  currentPumpStatus: string;
  lastMeterActivity: string;
};
type ProjectDetailsProps = {
  projectUser: {
    id: String;
    firstName: String;
    lastName: String;
  };
  projectOrg: {
    id: string;
    name: string;
  };
  beneficiaryName: string;
  companyName: string;
  address: {
    tole: string;
    ward: string;
    region: string;
    district: string;
  };
  user: string;
  profileImageUrl: string;
};
type ReferenceLineProps = {
  name: string;
  value: number;
  title?: string;
};

type RecentParams = {
  temperature: number;
  humidity: number;
  ECSensorTemperature: number;
  ECSensorHumidity: number;
  ECSensorMoisture: number;
  ECConductivity: number;
};

interface WeatherDetails {
  airTemperature: number;
  windSpeed: number;
  precipitationAmount: number;
  cloudAreaFraction: number;
  relativeHumidity: number;
}

const MeterDetails: React.FC = () => {
  const [filterOptions, setFilterOptions] = useState<MeterFilterProps>(initialfilterOptions);
  const [projectDetails, setProjectDetails] = useState({} as ProjectDetailsProps);
  const [projectMeterId, setprojectMeterId] = useState();
  const [projectHardwareId, setProjectHardwareId] = useState();
  const [graphData, setGraphData] = useState([] as any);
  const [referenceLine, setReferenceLine] = useState<ReferenceLineProps | null>();
  const [meterStatus, setMeterStatus] = useState({} as MeterStatusProps);
  const [recentParams, setRecentParams] = useState({} as RecentParams);

  const [yAxisLabel, setyAxisLabel] = useState("Temperature °C (degree celsius)");
  const [xAxisLabel, setxAxisLabel] = useState("Time");
  const [swps, sesetSwps] = useState("Y");
  const [sendTo, setSendTo] = useState("");
  //discharge
  const [discharge, setdischarge] = useState("");
  //runtime
  const [runtime, setRuntime] = useState("");
  //co2
  const [cotwo, setCotwo] = useState("");

  const { projectId } = useParams<{ projectId: string }>();
  const [day, setDay] = useState(new Date().getDate());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [refreshInvoker, setRefreshInvoker] = useState(new Date());
  const [refreshing, setRefreshing] = useState(false);
  const [choice, setChoice] = useState([0]);
  const [showCsvPopover, setShowCsvPopover] = useState(false);
  const [meterFilterConfigured, setMeterFilterConfigured] = useState(false);
  const [meterFilters, setMeterFilters] = useState();
  const [enabledMeterFilters, setEnabledMeterFilters] = useState<string[]>();
  const csvPopoverRef = useRef(null);
  const recommendationPopoverRef = useRef(null);

  const [showRecommendationsPopover, setShowRecommendationsPopover] = useState(false);
  const [recommendations, setRecommendations] = useState<any[]>([]);

  const [weatherData, setWeatherData] = useState<WeatherDetails | null>(null);
  // Use a flag to track if both requests are done
  const [requestsCompleted, setRequestsCompleted] = useState({
    projectData: false,
    weatherData: false,
  });
  // user role from token
  const { user }: any = useUserInfoContext();
  const userRole = user.role;
  const [csvDateRange, setCsvDateRange] = useState({
    startDate: "",
    endDate: "",
    interval: "1min",
  });
  const csvIntervalOptions = ["1min", "1hour", "1day"];

  const handleCsvPopup = () => {
    setShowCsvPopover(!showCsvPopover);
  };

  const handleCsvOverlayClose = () => {
    setShowCsvPopover(false);
  };

  const handleRecommendationsPopup = () => {
    setShowRecommendationsPopover(!showCsvPopover);
  };

  const handleRecommendationsOverlayClose = () => {
    setShowRecommendationsPopover(false);
  };

  const { dispatch } = useLayout();

  //weather
  const [weatherRefreshing, setWeatherRefreshing] = useState<boolean>(false);

  useEffect(() => {
    //create an array to loop from current year to 2020 in descending order
    const options: number[] = [];
    const start = new Date().getFullYear();
    const end = new Date("02/10/2020").getFullYear();
    let loop = start;
    while (loop >= end) {
      options.push(loop);
      loop = loop - 1;
    }

    setChoice(options);

    setHorizontalFluid(dispatch);
    return () => {
      resetHorizontalFluid(dispatch);
    };
  }, []);

  useEffect(() => {
    if (projectMeterId) {
      fetchRecentOnsiteParams(projectMeterId)
        .then((paramsRes: any) => {
          if (paramsRes?.data) {
            setRecentParams(paramsRes?.data[0]);
          }
        })
        .catch((err) => {
          toast.error("Error fetching recent onsite params!");
        });
    }
  }, [projectMeterId, swps, refreshInvoker]);

  useEffect(() => {
    setRefreshing(true); // Set loading to true initially
    const fetchData = async () => {
      try {
        // Fetching project and meter details
        const meterRes = await fetchMeterDetailByProjectId(projectId);
        const meterId = meterRes?.data?.projectMeter?.id;
        const hardwareId = meterRes?.data?.projectMeter?.hardwareId;
        sesetSwps(meterRes?.data?.projectMeter?.SWPS);
        setprojectMeterId(meterId);
        setProjectHardwareId(hardwareId);
        setSendTo(meterRes.data.status);

        if (meterId) {
          setFilterOptions({ ...filterOptions, meterId });

          const meterFilterRes = await getMeterFilterById(meterId);
          if (meterFilterRes?.data) {
            setMeterFilters(meterFilterRes.data);
            setMeterFilterConfigured(true);
          } else {
            setMeterFilterConfigured(false);
          }
        } else {
          throw new Error("Meter not installed");
        }

        // Fetching farmer details
        const farmerRes = await getFarmerById(projectId);
        if (!farmerRes?.data?.id) {
          throw new Error("Error while trying to fetch Project details.");
        }

        const data = farmerRes?.data;
        setProjectDetails({
          projectUser: {
            id: data?.projectUser?.id,
            firstName: data?.projectUser?.firstName,
            lastName: data?.projectUser?.lastName,
          },
          beneficiaryName: farmerRes?.data?.beneficiaryName,
          address: {
            district: data?.address?.district,
            region: data?.address?.region,
            tole: data?.address?.tole,
            ward: data?.address?.ward,
          },
          projectOrg: data?.projectOrg,
          companyName: data?.companyName,
          user: data?.userId,
          profileImageUrl: data?.profileImageUrl,
        });

        // Mark project data request as complete
        setRequestsCompleted((prev) => ({ ...prev, projectData: true }));
      } catch (err) {
        toast.error("Something went wrong");
      }
    };

    fetchData().finally(() => {
      // If both requests are done, stop the loading indicator
      if (requestsCompleted.weatherData) {
        setRefreshing(false);
      }
    });
  }, [projectId]);

  useEffect(() => {
    // If weatherData is available and complete, fetch recommendations
    if (
      !weatherData ||
      weatherData.airTemperature === undefined ||
      weatherData.windSpeed === undefined ||
      weatherData.precipitationAmount === undefined ||
      weatherData.relativeHumidity === undefined
    ) {
      return; // Skip if weatherData is not complete
    }

    (async () => {
      try {
        // Fetch recommendations
        const response = await getRecommendationsForCrops(projectId, weatherData);
        const recommendation = response?.data;
        setRecommendations(recommendation);

        if (!response?.data) {
          throw new Error(
            "Something went wrong while fetching recommendations. Please try again later"
          );
        }
        // Mark weather data request as complete
        setRequestsCompleted((prev) => ({ ...prev, weatherData: true }));
      } catch (err) {
        toast.error("Error Showing recommendations");
      }
    })().finally(() => {
      // If both requests are done, stop the loading indicator
      if (requestsCompleted.projectData) {
        setRefreshing(false);
      }
    });
  }, [weatherData]);
  useEffect(() => {
    //METER ACTIVITY STATUS
    (async () => {
      try {
        setRefreshing(true);
        const response = await getMeterActivityStatus(projectId);

        if (!response.data) {
          throw new Error("Something went wrong while fetching  meter activity status details");
        }
        const meterActivityData = response?.data?.projectMeter?.meterData[0];

        //received status data
        const pumpStatus = response?.data?.projectMeter?.status;
        const meterLastReceivedAt = meterActivityData?.receivedAt;

        // Converting to usuable info
        const currentPumpStatus = pumpStatus === "Y" ? "Active" : "Disabled";
        const currentGMTTime = getCurrentGmtTime();
        const timeSinceLastActivity = currentGMTTime - new Date(meterLastReceivedAt).getTime(); //milliseconds

        const formattedTimeSinceLastActivity = formatTimeDuration(timeSinceLastActivity);

        setMeterStatus({
          currentMeterStatus:
            timeSinceLastActivity < 1000 * 60 * 60 && timeSinceLastActivity > 0
              ? "Active"
              : "Inactive",
          currentPumpStatus: currentPumpStatus,
          lastMeterActivity: formattedTimeSinceLastActivity,
        });
      } catch (err) {
        toast.error("Something went wrong ");
      } finally {
        stopLoading();
        setRefreshing(false);
      }
    })();
  }, [refreshInvoker]);

  useEffect(() => {
    (async () => {
      try {
        setRefreshing(true);

        //GRAPH DATA
        let response, data: { name: string; value: number }[];

        if (filterOptions.meterId) {
          switch (filterOptions.frequency) {
            case FilterByFrequency.DAILY:
              response = await meterDetailsDaily(filterOptions);
              data = response?.data?.data?.rows;

              break;
            case FilterByFrequency.MONTHLY:
              const monthDayList = makeDayListForMonth(month);
              data = monthDayList.map((each) => ({
                name: `${each}`,
                value: 0,
              }));

              response = await getMonthlyDetails({
                ...filterOptions,
                from: `${year}-${month}-${monthDayList[0]}`,
                to: `${year}-${month}-${monthDayList[monthDayList.length - 1]}`,
              });
              response?.data?.data?.rows.forEach((each: any) => {
                data[parseInt(each.name.split(":")[1]) - 1].value = each.value;
              });
              break;
            case FilterByFrequency.YEARLY:
              data = shortMonths.map((each) => ({
                name: `${each}`,
                value: 0,
              }));

              response = await getYearlyDetails({
                ...filterOptions,
                from: `${year}-01-01`,
                to: `${year}-12-31`,
              });
              response?.data?.data?.rows.forEach((each: any) => {
                data[parseInt(each.name) - 1].value = each.value;
              });
              break;
            default:
              data = [];
              break;
          }
          const graphDataResponse: GraphDataResponseProps = response?.data;
          setGraphData(data);
          if (
            filterOptions.filter == SystemParams.DISCHARGE ||
            filterOptions.filter == SystemParams.FLOW
          ) {
            setReferenceLine({
              name: "Claimed Flow/Discharge",
              title: "Discharge",
              value: parseInt(graphDataResponse.meterStats.flow as string),
            });
          } else if (filterOptions.filter == SystemParams.HEAD) {
            setReferenceLine({
              name: "Claimed Head",
              title: "Head",
              value: parseInt(graphDataResponse.meterStats.head as string),
            });
          } else {
            setReferenceLine(null);
          }
          setdischarge(response?.data?.meterStats?.discharge);
          setRuntime(response?.data?.meterStats?.runTime);
          setCotwo(response?.data?.meterStats?.co2Curbed);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setTimeout(() => {
          setRefreshing(false);
        }, 500);
      }
    })();
  }, [filterOptions, refreshInvoker]);

  //handlers
  const toggleActiveHandler = (action: string) => async () => {
    try {
      let response;
      response = action === "ENABLE" && (await enablePump(filterOptions.meterId));
      response = action === "DISABLE" && (await disablePump(filterOptions.meterId));
      if (!response) {
        throw new Error("Something went wrong while trying to perform that action");
      }
    } catch (err) {
    } finally {
      setRefreshInvoker(new Date());
    }
  };

  const applyDateFilters = () => {
    setFilterOptions({
      ...filterOptions,
      from: `${year}-${month}-${day}`,
      to: `${year}-${month}-${day}`,
    });
  };

  const filterSelectHandler = (value: SystemParams | EnvironmentalParams, name?: string) => () => {
    setFilterOptions({ ...filterOptions, filter: value });
    if (name) {
      // setyAxisLabel(name);
      if (name === "Solar Current") {
        setyAxisLabel("A (Ampere)");
      } else if (name === "Solar Voltage") {
        setyAxisLabel("Solar Voltage V (Voltage)");
      } else if (name === "Head") {
        setyAxisLabel("Head m (meters)");
      } else if (name === "Power") {
        setyAxisLabel("Power W (watt)");
      } else if (name === "Discharge") {
        setyAxisLabel("Discharge L (Liters)");
      } else if (name === "Temperature") {
        setyAxisLabel("Temperature °C (degree celsius)");
      } else if (name === "Humidity") {
        setyAxisLabel("Humidity % (percent Relative Humidity)");
      } else if (name === "EC Sensor Temperature") {
        setyAxisLabel("EC Sensor Temperature °C (degree celsius)");
      } else if (name === "EC Sensor Moisture") {
        setyAxisLabel("EC Sensor Moisture %");
      } else if (name === "EC Conductivity") {
        setyAxisLabel("EC Conductivity μS/m (micro-siemens/meter)");
      } else if (name === "EC Sensor Humidity") {
        setyAxisLabel("EC Sensor Humidity %");
      } else if (name === "Runtime") {
        setyAxisLabel("Runtime min (Minutes)");
      }
    }
  };

  const refreshHandler = () => {
    setRefreshing(true);
    setRefreshInvoker(new Date());
  };

  const stopLoading = () => {
    setTimeout(() => {
      setRefreshing(false);
    }, 500);
  };
  //DATA FILTER OPTIONS

  useEffect(() => {
    if (meterFilters) {
      const trueFilterOptions = Object.keys(meterFilters).filter(
        (key) => meterFilters[key] === true
      );

      setEnabledMeterFilters(trueFilterOptions);
    }
  }, [meterFilters]);

  let filterBySystemParamsOptions: {
    system: Array<SystemParamsOptionProps>;
    environmental: Array<SystemParamsOptionProps>;
  };
  switch (filterOptions.frequency) {
    case FilterByFrequency.DAILY:
      filterBySystemParamsOptions = {
        system: [
          { name: "Solar Current", value: SystemParams.SOLAR_CURRENT },
          { name: "Solar Voltage", value: SystemParams.SOLAR_VOLTAGE },
          { name: "Head", value: SystemParams.HEAD },
          { name: "Power", value: SystemParams.POWER },
          { name: "Discharge", value: SystemParams.FLOW }, //Discharge is the same as flow in daily
        ],
        environmental: [
          { name: "Temperature", value: EnvironmentalParams.TEMPERATURE },
          { name: "Humidity", value: EnvironmentalParams.HUMIDITY },
          { name: "EC Sensor Temperature", value: EnvironmentalParams.EC_SENSOR_TEMPERATURE },
          { name: "EC Sensor Humidity", value: EnvironmentalParams.EC_SENSOR_HUMIDITY },
          { name: "EC Sensor Moisture", value: EnvironmentalParams.EC_SENSOR_MOISTURE },
          { name: "EC Conductivity", value: EnvironmentalParams.EC_CONDUCTIVITY },
        ],
      };
      break;
    case FilterByFrequency.MONTHLY:
      filterBySystemParamsOptions = {
        system: [
          { name: "Head", value: SystemParams.HEAD },
          { name: "Runtime", value: SystemParams.RUNTIME },
          { name: "Discharge", value: SystemParams.DISCHARGE },
          { name: "Power", value: SystemParams.POWER },
        ],
        environmental: [
          { name: "Temperature", value: EnvironmentalParams.TEMPERATURE },
          { name: "Humidity", value: EnvironmentalParams.HUMIDITY },
          { name: "EC Sensor Temperature", value: EnvironmentalParams.EC_SENSOR_TEMPERATURE },
          { name: "EC Sensor Humidity", value: EnvironmentalParams.EC_SENSOR_HUMIDITY },
          { name: "EC Sensor Moisture", value: EnvironmentalParams.EC_SENSOR_MOISTURE },
          { name: "EC Conductivity", value: EnvironmentalParams.EC_CONDUCTIVITY },
        ],
      };
      break;
    case FilterByFrequency.YEARLY:
      filterBySystemParamsOptions = {
        system: [
          { name: "Head", value: SystemParams.HEAD },
          { name: "Runtime", value: SystemParams.RUNTIME },
          { name: "Discharge", value: SystemParams.DISCHARGE },
          { name: "Power", value: SystemParams.POWER },
        ],
        environmental: [
          { name: "Temperature", value: EnvironmentalParams.TEMPERATURE },
          { name: "Humidity", value: EnvironmentalParams.HUMIDITY },
          { name: "EC Sensor Temperature", value: EnvironmentalParams.EC_SENSOR_TEMPERATURE },
          { name: "EC Sensor Humidity", value: EnvironmentalParams.EC_SENSOR_HUMIDITY },
          { name: "EC Sensor Moisture", value: EnvironmentalParams.EC_SENSOR_MOISTURE },
          { name: "EC Conductivity", value: EnvironmentalParams.EC_CONDUCTIVITY },
        ],
      };
      break;
    default:
      filterBySystemParamsOptions = { system: [], environmental: [] };
      break;
  }
  const filterByFrequencyButtons = [
    { name: "Daily", value: FilterByFrequency.DAILY, xAxis: "Time" },
    { name: "Monthly", value: FilterByFrequency.MONTHLY, xAxis: "Days" },
    { name: "Yearly", value: FilterByFrequency.YEARLY, xAxis: "Months" },
  ];

  const getDischarge = () => {
    let result,
      tempDischarge = Number(discharge || "0");
    if (tempDischarge < 1000) {
      result = ` ${tempDischarge} L`;
    } else if (tempDischarge < 1000000) {
      result = ` ${(tempDischarge / 1000).toFixed(2)} KL`;
    } else if (tempDischarge >= 1000000) {
      result = ` ${(tempDischarge / 1000000).toFixed(2)} ML`;
    }

    return result;
  };

  const getRuntime = () => {
    let result,
      tempRuntime = Number(runtime);
    if (tempRuntime > 60) {
      result = ` ${(tempRuntime / 60).toFixed(2)} hr`;
    } else {
      result = ` ${tempRuntime || 0}  min`;
    }

    return result;
  };

  const getCO2Curbed = () => {
    return `${Number(cotwo || 0).toFixed(2)} Tonne`;
  };
  const handleCsvChange = (e: any) => {
    const { name, value } = e.target;
    setCsvDateRange({ ...csvDateRange, [name]: value });
  };

  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleCsvSubmit = async (e: any) => {
    e.preventDefault();
    if (!csvDateRange.startDate || !csvDateRange.endDate) {
      toast.error("Enter the date range!");
      return;
    }

    if (new Date(csvDateRange.startDate) > new Date(csvDateRange.endDate)) {
      toast.error("End date must be greater than start date.");
      return;
    }

    const payload = {
      fromDate: csvDateRange.startDate,
      toDate: csvDateRange.endDate,
      meterId: projectMeterId,
      hardwareId: projectHardwareId,
      interval: csvDateRange.interval,
    };
    const response = await downloadCsvReport(payload);

    if (response.data.message === "DATA_NOT_AVAILABLE") {
      return toast.error("No data found on the given period.");
    }

    downloadFile({
      data: response.data,
      fileName: `meter-${projectMeterId}`,
      fileType: "text/csv",
    });
  };

  const onRefreshHandle = () => {
    setWeatherRefreshing(true);
  };

  const resetRefreshWeather = () => {
    setWeatherRefreshing(false);
  };

  const handleWeatherInformation = (data: any) => {
    setWeatherData(data);
  };

  return (
    <div className="meter-details mb-5">
      <Row className="xs-child-gap-large">
        <Col xs={12} md={9}>
          <div className="farmer-detail d-flex flex-wrap">
            <img src={projectDetails.profileImageUrl} alt={projectDetails.beneficiaryName} />
            <div>
              <h3>{projectDetails.beneficiaryName}</h3>
              <p>{`${projectDetails?.address?.tole} tole, Ward no: ${projectDetails?.address?.ward}, ${projectDetails?.address?.district}, ${projectDetails?.address?.region}`}</p>
              <p className="mb-3">
                Monitored by <b>{projectDetails?.projectOrg?.name || "Gham Power Nepal"}</b>
              </p>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  if (sendTo === "REGISTERED") {
                    history.push(`/project-status/${projectId}/registration`);
                  } else if (sendTo == "INITIALIZED") {
                    history.push(`/project-status/${projectId}/initiation`);
                  } else if (sendTo == "INSTALLED") {
                    history.push(`/project-status/${projectId}/installation`);
                  } else if (sendTo == "APPROVED") {
                    history.push(`/project-status/${projectId}/installation`);
                  } else if (sendTo == "DISPATCHED") {
                    history.push(`/project-status/${projectId}/operational`);
                  } else if (sendTo == "OPERATIONAL") {
                    history.push(`/project-status/${projectId}/operational`);
                  } else {
                    history.push(`/project-status/${projectId}/registration`);
                  }
                }}
              >
                View Project Details
              </Button>
            </div>
          </div>
        </Col>

        <Col xs={12} md={3}>
          {userRole === "SUPERADMIN" && (
            <>
              <h6 className="title">System Control</h6>
              <ToggleButtonGroup
                id="buttongroup"
                name="buttongroup"
                defaultValue={meterStatus.currentPumpStatus === "Active" ? [1] : [2]}
                key={Math.random()}
              >
                <ToggleButton
                  id="Enable"
                  name="Enable"
                  value={1}
                  variant="outline-primary"
                  onClick={toggleActiveHandler("ENABLE")}
                >
                  Enable
                </ToggleButton>
                <ToggleButton
                  id="Disable"
                  name="Disable"
                  value={2}
                  variant="outline-primary"
                  onClick={toggleActiveHandler("DISABLE")}
                >
                  Disable
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}

          <h6 className="title mt-4">System Status</h6>
          <div className="sys-status mb-3">
            <p>
              Current Meter Status: <b>{meterStatus.currentMeterStatus}</b>
            </p>
            <p>
              Current Pump Status: <b>{meterStatus.currentPumpStatus}</b>
            </p>
            <p>
              Last Meter Activity: <b>{meterStatus.lastMeterActivity}</b>
            </p>
          </div>

          <div className="d-flex flex-column flex-md-row gap-2 flex-wrap">
            <Button variant="primary" ref={csvPopoverRef} onClick={handleCsvPopup}>
              Generate CSV report
            </Button>

            {recommendations.length > 0 && (
              <Button
                variant="outline-primary"
                ref={recommendationPopoverRef}
                onClick={handleRecommendationsPopup}
                className="d-flex gap-3 align-items-center justify-content-center"
              >
                <div
                  style={{ width: "10px", height: "10px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img
                    src={RedDot}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                    className="img-fluid"
                  />
                </div>
                Today's Recommendations
              </Button>
            )}
          </div>

          <Overlay
            show={showCsvPopover}
            target={csvPopoverRef.current}
            placement="bottom"
            container={document.body}
            onHide={handleCsvOverlayClose}
            rootClose
          >
            <Popover
              id="popover-basic"
              style={{
                margin: "10px",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              <Popover.Body>
                <form action="" onSubmit={handleCsvSubmit}>
                  <div className="mb-2">
                    <Form.Label htmlFor="csvStartDate">Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={csvDateRange.startDate}
                      onChange={handleCsvChange}
                    />
                  </div>
                  <div className="mb-2">
                    <Form.Label htmlFor="csvEndDate">End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={csvDateRange.endDate}
                      onChange={handleCsvChange}
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Label htmlFor="interval">Interval</Form.Label>
                    <Form.Select name="interval" onChange={handleCsvChange}>
                      {csvIntervalOptions.map((option) => (
                        <option value={option} key={option}>
                          {option.replace(/^(\d+)([a-zA-Z]+)/, "$1 $2")}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <Button variant="outline-primary" type="submit" style={{ width: "100%" }}>
                    <Download /> Download
                  </Button>
                </form>
              </Popover.Body>
            </Popover>
          </Overlay>

          <Modal
            centered
            show={showRecommendationsPopover}
            onHide={handleRecommendationsOverlayClose}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title className="px-4 py-2">Today's Recommendation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column gap-3 px-4 pt-2 pb-4">
                {recommendations &&
                  recommendations.length > 0 &&
                  (recommendations.length === 1 ? (
                    <div>
                      <p className="d-flex flex-column gap-1 mb-0">
                        <p style={{ fontWeight: "bold" }}>Recommendation:</p>{" "}
                        {recommendations[0].advisory}
                      </p>
                    </div>
                  ) : (
                    recommendations.map((recommendation, index) => (
                      <div key={index}>
                        <p className="d-flex flex-column gap-2 mb-0">
                          <p style={{ fontWeight: "bold" }} className="mb-0">{`Recommendation ${
                            index + 1
                          }:`}</p>{" "}
                          {`${recommendation.advisory}`}
                        </p>
                      </div>
                    ))
                  ))}
              </div>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "4rem",
        }}
      >
        <h2>Gham Power Krishi Meter Live Data</h2>
      </div>
      <div className="graph mt-4">
        <div className="header mb-4">
          <Row className="flex-fill">
            <Col xs={9} className=" d-flex align-items-center">
              <span className="g-date">{filterOptions.from}</span>
            </Col>
            <Col xs={3} className=" d-flex align-items-center justify-content-end">
              <Button variant="outline-primary" className="refresh-btn" onClick={refreshHandler}>
                {refreshing ? (
                  <>
                    <Spinner animation="border" variant="secondary" />
                    {/* <span className="d-none d-lg-inline">Refreshing</span> */}
                  </>
                ) : (
                  <>
                    <BiRefresh size="35" style={{ marginRight: "5px" }} />
                    {/* <span className="d-none d-lg-inline">Refresh Data</span> */}
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </div>
        <Row className=" px-md-3 mb-2">
          <div className="d-flex justify-content-between graph-box">
            {swps && swps === "Y" ? (
              <>
                <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={TotalDischarge}
                      className="meter_Icons me-2"
                      alt="Total discharge Icon"
                      height={35}
                      width={35}
                    />
                    <h6 className="p-0 m-0 meter-value">{`${getDischarge()} `}</h6>
                  </div>
                  <p className="meter-icon-text mt-2 w-a pl-2">Total Discharge</p>
                </div>
                <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={TotalRuntime}
                      className="meter_Icons me-2"
                      alt="Total runtime icon"
                      height={35}
                      width={35}
                    />
                    <h6 className="p-0 m-0 meter-value">{`${getRuntime()} `}</h6>
                  </div>
                  <p className="meter-icon-text mt-2 w-a pl-2">Total Runtime</p>
                </div>
                <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={Carbondioxide}
                      className="meter_Icons me-2"
                      alt="carbondioxide curbed icon"
                      height={35}
                      width={35}
                    />
                    <h6 className="p-0 m-0 meter-value">{getCO2Curbed()}</h6>
                  </div>
                  <p className="meter-icon-text mt-2 w-a pl-2">Carbondioxide Curbed</p>
                </div>
              </>
            ) : null}
            {recentParams?.temperature !== undefined ? (
              <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                <div className="d-flex align-items-center">
                  <img
                    src={Temperature}
                    className="meter_Icons me-2"
                    alt="thermometer icon"
                    height={35}
                    width={35}
                  />
                  <h6 className="p-0 m-0 meter-value">{`${recentParams?.temperature || 0}`}</h6>
                </div>
                <p className="meter-icon-text mt-2 w-a pl-2">Temperature</p>
              </div>
            ) : null}
            {recentParams?.humidity !== undefined ? (
              <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                <div className="d-flex align-items-center">
                  <img
                    src={Humidity}
                    className="meter_Icons me-2"
                    alt="humidity icon"
                    height={35}
                    width={35}
                  />

                  <h6 className="p-0 m-0 meter-value">{`${recentParams?.humidity || 0}`}</h6>
                </div>
                <p className="meter-icon-text mt-2 w-a pl-2">Humidity</p>
              </div>
            ) : null}
            {recentParams?.ECSensorTemperature !== undefined ? (
              <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                <div className="d-flex align-items-center">
                  <img
                    src={ECSensorTemp}
                    className="meter_Icons me-2"
                    alt="ECSensorTempIcon"
                    height={35}
                    width={35}
                  />
                  <h6 className="p-0 m-0 meter-value">{`${
                    recentParams?.ECSensorTemperature || 0
                  }`}</h6>
                </div>
                <p className="meter-icon-text mt-2 w-a">EC Sensor Temperature</p>
              </div>
            ) : null}
            {recentParams?.ECSensorHumidity !== undefined ? (
              <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                <div className="d-flex align-items-center">
                  <img
                    src={ECSensorHumidity}
                    className="meter_Icons me-2"
                    alt="ECSensorTempIcon"
                    height={35}
                    width={35}
                  />
                  <h6 className="p-0 m-0 meter-value">{`${
                    recentParams?.ECSensorHumidity || 0
                  }`}</h6>
                </div>
                <p className="meter-icon-text mt-2 w-a pl-2">EC Sensor Humidity</p>
              </div>
            ) : null}
            {recentParams?.ECSensorMoisture !== undefined ? (
              <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                <div className="d-flex align-items-center">
                  <img
                    src={ECSensorMoisture}
                    className="meter_Icons me-2"
                    alt="ECSensorTempIcon"
                    height={35}
                    width={35}
                  />
                  <h6 className="p-0 m-0 meter-value">{`${
                    recentParams?.ECSensorMoisture || 0
                  }`}</h6>
                </div>
                <p className="meter-icon-text mt-2 w-a pl-2">EC Sensor Moisture</p>
              </div>
            ) : null}
            {recentParams?.ECConductivity !== undefined ? (
              <div className="d-flex flex-column align-items-start meter-detail pe-5 pb-4">
                <div className="d-flex align-items-center">
                  <img
                    src={ECConductivity}
                    className="meter_Icons me-2"
                    alt="ECSensorTempIcon"
                    height={35}
                    width={35}
                  />
                  <h6 className="p-0 m-0 meter-value">{`${recentParams?.ECConductivity || 0}`}</h6>
                </div>
                <p className="meter-icon-text mt-2 w-a pl-2">EC Conductivity</p>
              </div>
            ) : null}
          </div>
        </Row>
        <Chart
          graphData={graphData}
          referenceLine={referenceLine}
          filterOptions={filterOptions}
          labels={{ yAxisLabel: yAxisLabel, xAxisLabel: xAxisLabel }}
          type={
            filterOptions.frequency == FilterByFrequency.DAILY
              ? ChartTypes.LINEAR_AREA
              : ChartTypes.BAR
          }
          tooltipLabelType={
            filterOptions.frequency == FilterByFrequency.DAILY
              ? TooltipLabelTypes.DATE
              : TooltipLabelTypes.STATIC
          }
        />
        <div className="footer my-4 px-2 px-lg-5">
          <Row>
            <Col lg={7}>
              <Row>
                {swps === "Y" && (
                  <Col md={6}>
                    <h6 className="title">System Parameters</h6>
                    <ul className="sys-params" style={{ display: "block" }}>
                      {meterFilterConfigured
                        ? filterBySystemParamsOptions.system
                            .filter((each) => {
                              if (enabledMeterFilters)
                                return enabledMeterFilters.includes(each.value);
                            })
                            .map((each) => (
                              <li
                                onClick={filterSelectHandler(each.value, each.name)}
                                key={each.value}
                              >
                                <span
                                  className={filterOptions.filter === each.value ? "active" : ""}
                                >
                                  {each.name}
                                </span>
                              </li>
                            ))
                        : filterBySystemParamsOptions.system.map((each) => (
                            <li
                              onClick={filterSelectHandler(each.value, each.name)}
                              key={each.value}
                            >
                              <span className={filterOptions.filter === each.value ? "active" : ""}>
                                {each.name}
                              </span>
                            </li>
                          ))}
                    </ul>
                  </Col>
                )}
                <Col md={6}>
                  <h6 className="title">On-site Parameters</h6>
                  <ul className="sys-params" style={{ display: "block" }}>
                    {meterFilterConfigured
                      ? filterBySystemParamsOptions.environmental
                          .filter((each) => {
                            if (enabledMeterFilters)
                              return enabledMeterFilters.includes(each.value);
                          })
                          .map((each) => (
                            <li
                              onClick={filterSelectHandler(each.value, each.name)}
                              key={each.value}
                            >
                              <span className={filterOptions.filter === each.value ? "active" : ""}>
                                {each.name}
                              </span>
                            </li>
                          ))
                      : filterBySystemParamsOptions.environmental.map((each) => (
                          <li onClick={filterSelectHandler(each.value, each.name)} key={each.value}>
                            <span className={filterOptions.filter === each.value ? "active" : ""}>
                              {each.name}
                            </span>
                          </li>
                        ))}
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="filter">
              <p className="title mb-3">Data Filtration</p>
              <ButtonGroup>
                {filterByFrequencyButtons.map((each) => (
                  <Button
                    key={each.value}
                    title={each.name}
                    size="sm"
                    variant="outline-primary"
                    className={each.value === filterOptions.frequency ? "btn-aepc-dark" : ""}
                    onClick={() => {
                      setyAxisLabel("Temperature °C (degree celsius)");

                      setxAxisLabel(each.xAxis);
                      setFilterOptions({
                        ...filterOptions,
                        frequency: each.value,
                        filter: EnvironmentalParams.TEMPERATURE,
                      });
                    }}
                  >
                    {each.name}
                  </Button>
                ))}
              </ButtonGroup>

              <div className="date-filter mt-3 mb-3">
                {filterOptions.frequency === FilterByFrequency.DAILY && (
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      // @ts-ignore
                      setDay(Number(e.target.value));
                    }}
                    defaultValue={day}
                  >
                    {Array.from(Array(months(year)[month - 1].days).keys()).map((d) => (
                      <option value={d + 1} key={d + 1}>
                        {d + 1}
                      </option>
                    ))}
                  </Form.Select>
                )}

                {filterOptions.frequency !== FilterByFrequency.YEARLY && (
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      setMonth(Number(e.currentTarget.value));
                      if (day > months(year)[parseInt(e.currentTarget.value) - 1].days) {
                        setDay(1);
                      }
                    }}
                    defaultValue={month}
                  >
                    {months().map((eachMonth) => (
                      <option key={eachMonth.number} value={eachMonth.number}>
                        {eachMonth.short}
                      </option>
                    ))}
                  </Form.Select>
                )}

                <Form.Select
                  size="sm"
                  onChange={(e) => {
                    if (day > months(parseInt(e.currentTarget.value))[month - 1].days) {
                      setDay(1);
                    }
                    setYear(Number(e.currentTarget.value));
                  }}
                  defaultValue={year}
                >
                  {choice.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <Button className="btn-aepc-dark" onClick={applyDateFilters}>
                Apply Filter
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "4rem",
        }}
      >
        <h2>Live Weather Update</h2>

        <Button variant="outline-primary" className="refresh-btn" onClick={onRefreshHandle}>
          {refreshing ? (
            <>
              <Spinner animation="border" variant="secondary" />
              {/* <span className="d-none d-lg-inline">Refreshing</span> */}
            </>
          ) : (
            <>
              <BiRefresh size="35" style={{ marginRight: "5px" }} />
              {/* <span className="d-none d-lg-inline">Refresh Data</span> */}
            </>
          )}
        </Button>
      </div>
      <Weather
        refreshingWeather={weatherRefreshing}
        resetRefresh={resetRefreshWeather}
        weatherInformation={handleWeatherInformation}
      />

      {refreshing && <LoadingOverlay />}
    </div>
  );
};

export default MeterDetails;
