import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FilePickerTwo from "../../../components/form/FilePickerTwo";
import { BiFolder } from "react-icons/bi";

import { useRefreshAll } from "./FilesAndLog";

//Shared
import { LoadingOverlay } from "../../../components/shared";
import { uploadFile, getFarmerById, updateProject } from "../../../../helpers/api";
import { useProjectDetailsContext } from "../../../context/ProjectDetailsContext";

const UploadDocument = (props: any) => {
  const refreshAll = useRefreshAll();
  const { projectId } = useParams() as any;
  const [nameOfFile, setNameOfFile] = useState("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(new Date());
  const {projectDetails, setProjectDetails} = useProjectDetailsContext()

  useEffect(() => {
    //did not use context here to avoid interfering with the refresh mechanism
      getFarmerById(projectId).then((res: any) => {
      setProjectDetails(res.data);
    });
  }, [projectId, refresh]);

  //fileSelect
  const onFileSelect = (fileName: string) => async (file: File) => {
    const upload = async () => {
      var bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);
      bodyFormData.append("documentType", fileName);
      bodyFormData.append("projectId", projectId);

      return uploadFile(bodyFormData).then((res: any) => {
        toast.success("File uploaded successfully");
      });
    };
    try {
      setUploading(true);
      let fileUrl = await upload();
    } finally {
      setUploading(false);
    }
  };

  //submit
  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);

    updateProject(projectDetails)
      .then((res: any) => {
        toast.success("File saved successfully");
      })

      .finally(() => {
        props.onHide();
        setUploading(false);
        refreshAll();
        setRefresh(new Date());
      });
  };

  return (
    <div>
      {" "}
      {uploading && <LoadingOverlay />}
      <Modal
        dialogClassName="border-radius-2"
        {...props}
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div style={{ padding: "30px" }}>
          <Modal.Header style={{ borderColor: "white" }}>
            <Modal.Title id="contained-modal-title-vcenter">Project Document</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleOnSubmit}>
            <Modal.Body className="show-grid">
              <h6 className="mb-3">Document Category</h6>
              <Form.Select
                size="sm"
                className="mb-3 w-50"
                style={{
                  backgroundColor: "#EEECEC",
                  border: "none",
                  height: "46px",
                  borderRadius: "9px",
                }}
                onChange={(e: any) => {
                  setNameOfFile(e.target.value);
                }}
              >
                <option value="">Choose Documnet Category</option>
                <option value="citizenshipUrl">Citizenship</option>
                <option value="landOwnershipUrl">LandOwnership Document</option>
                <option value="profileImageUrl">Profile Image</option>

                {!!projectDetails?.agreementDocUrl && (
                  <option value="agreementDocUrl">Agreement Document</option>
                )}

                {!!projectDetails?.workOrderDocUrl && (
                  <option value="workOrderDocUrl">Work Order Document</option>
                )}
              </Form.Select>

              {nameOfFile ? (
                <FilePickerTwo
                  id={nameOfFile}
                  onFileSelect={onFileSelect(nameOfFile)}
                  placeholder="Choose File"
                />
              ) : null}

              <div className="mt-4">
                {!!projectDetails?.citizenshipUrl && (
                  <Row style={{ fontSize: "13px" }}>
                    <Col xl={2} lg={2} md={2} xs={12}>
                      <BiFolder size={45} color="silver" />
                    </Col>
                    <Col xl={2} lg={2} md={2} xs={12}>
                      <p>
                        <b>Citizenship</b> <br />
                        ACIM0001256478.pdf
                        <br />
                        0.8 MB
                      </p>
                    </Col>
                  </Row>
                )}

                {!!projectDetails?.landOwnershipUrl && (
                  <Row style={{ fontSize: "13px" }}>
                    <Col xl={2} lg={2} md={2} xs={12}>
                      <BiFolder size={45} color="silver" />
                    </Col>
                    <Col xl={9} lg={9} md={9} xs={12}>
                      <p>
                        <b>Land Ownership Document</b>
                        <br />
                        ACIM0001256478.pdf
                        <br />
                        0.8 MB
                      </p>
                    </Col>
                  </Row>
                )}

                {!!projectDetails?.profileImageUrl && (
                  <Row style={{ fontSize: "13px" }}>
                    <Col xl={2} lg={2} md={2} xs={12}>
                      <BiFolder size={45} color="silver" />
                    </Col>
                    <Col xl={9} lg={9} md={9} xs={12}>
                      <b>Profile Image</b>
                      <br />
                      DCIM0001256478.jpeg
                      <br />
                      0.8 MB
                    </Col>
                  </Row>
                )}
                {!!projectDetails?.agreementDocUrl && (
                  <Row style={{ fontSize: "13px" }} className="mt-3">
                    <Col xl={2} lg={2} md={2} xs={12}>
                      <BiFolder size={45} color="silver" />
                    </Col>
                    <Col xl={9} lg={9} md={2} xs={12}>
                      <b>Agreement Document</b>
                      <br />
                      ACIM0001256478.pdf
                      <br />
                      0.8 MB
                    </Col>
                  </Row>
                )}
                {!!projectDetails?.workOrderDocUrl && (
                  <Row style={{ fontSize: "13px" }} className="mt-3">
                    <Col xl={2} lg={2} md={2} xs={12}>
                      <BiFolder size={45} color="silver" />
                    </Col>
                    <Col xl={9} lg={9} md={9} xs={12}>
                      <b>WorkOrder Document</b>
                      <br />
                      ACIMs01256478.pdf
                      <br />
                      0.89 MB
                    </Col>
                  </Row>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer
              className="save_btnPosition"
              style={{ display: "flex", justifyContent: "center", borderColor: "white" }}
            >
              {nameOfFile ? (
                <Button size="sm" className="save_btn m-lg-3 " type="submit">
                  Save & Continue
                </Button>
              ) : null}
              <Button
                className="save_btn m-lg-2"
                variant="outline-primary"
                size="sm"
                onClick={props.onHide}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default UploadDocument;
