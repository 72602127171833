import React, { ChangeEvent, useEffect,  useState } from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AdvisoryParameter, AdvisoryParameterName, AdvisoryParameterRange, AdvisoryType, CropDataType } from "../../ts/types";
import { InitialCropData } from "./crops";
import { addCrops, editCropRecommendation, getAllCrops, getCropRecommendations, postCropRecommendations } from "../../../helpers/api";
import { toast } from "react-toastify";
import history from "../../../history";
import { LoadingOverlay } from "../../components/shared";
import MinusSign from "../../../assets/icons/minus-red.svg";
import { camelToFlat, environmentalParameters, flatToCamel, systemParameters, weatherValues } from "../../constants/crop";
import AdvisoryList from "../../components/crops/advisory/AdvisoryList";

const AddCrops = () => {
  const [editAdvisory,setEditAdvisory] = useState<AdvisoryType&{id:string}|null>(null)

  const [uploading, setUploading] = useState<boolean>(false);
  // inputdata to add new crops
  const [inputData, setInputData] = useState<CropDataType>(InitialCropData);

  // state for crop list for diplaying the crop in the exists section
  const [cropList, setCropList] = useState<string[]>([]);

  const [advisoryParameters, setAdvisoryParameters] = useState<AdvisoryParameter>(editAdvisory?.parameters||{});

  const [advisory,setAdvisory] = useState<string|undefined>(editAdvisory?.advisory||'')

  const [selectedValues, setSelectedValues] = useState<string[]>([]);


  const[cropAdvisoryList,setCropAdvisoryList] = useState<((AdvisoryType&{id:string})[])|[]>([])
  
  const [cropExist, setCropExist] = useState<boolean>(false);

  // for selecting crops
  const [cropTypes, setCropTypes] = useState<string[]>([]);


  // to show the selected crop List for the advisory
  const [selectedCrops, setSelectedCrops] = useState<string[]>(editAdvisory?.crops||[]);



  // submit function to add the crops in the database
  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);
    const upperCaseCropList = cropList.map((crop) => crop.toUpperCase());
    const inputCropName = inputData.name.toUpperCase();

    if (!inputData.name) {
      toast.error("Please fill the crop name in the necessary fields");
      setUploading(false);
    } else if (upperCaseCropList.includes(inputCropName)) {
      toast.error("Crops already exist, PLease add a different crop.");
    } else {
      addCrops(inputData)
        .then((res: any) => {
          if (res.status) {
            toast.success("Crops added successfully");
            history.push(`/add-crops`);
            setInputData(InitialCropData);
          } else {
            toast.error("Error adding the crops");
          }
        })
        .finally(() => setUploading(false));
    }
  };


  // to validate if the entered crops name already exist in the list
  const handleOnChange = (inputKey: keyof CropDataType) => (e: ChangeEvent<any>) => {
    const value = e.target.value;
    const upperCaseValue = value.toUpperCase();

    setInputData((prevData) => ({
      ...prevData,
      name: upperCaseValue,
    }));

    const lowerCaseCropList = cropList.map((crop) => crop.toLowerCase());
    const inputCropName = value.toLowerCase();
    if (lowerCaseCropList.includes(inputCropName)) {
      setCropExist(true);
    } else {
      setCropExist(false);
    }
  };

  // get all the crops to display in the exisiting crops section and update the list on new addition
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getAllCrops();
      const cropNames = data.map((crop: { id: string; name: string }) => crop.name);
      setCropList(cropNames);
    };
    fetchData();
  }, [inputData]);

    // get all the crop names that exist and generate a list for dropdown.
    useEffect(() => {
      const fetchData = async () => {
        const { data } = await getAllCrops();
        const cropNames = data?.map((crop: { id: string; name: string }) => crop.name);
        setCropTypes(cropNames);
      };
      fetchData();
    }, [cropList]);
    useEffect(() => {
      if(editAdvisory){
        setAdvisoryParameters(editAdvisory?.parameters)
        const newSelectedValues:string[] = []
        if(editAdvisory?.parameters){
          Object.keys(editAdvisory?.parameters).forEach((param)=>{
            newSelectedValues.push(camelToFlat[param as keyof typeof camelToFlat])
          })
        }
        if(editAdvisory?.crops){
          setSelectedCrops(editAdvisory?.crops)
        }
        if(editAdvisory?.advisory){
          setAdvisory(editAdvisory?.advisory)
        }
        setSelectedValues(newSelectedValues)
      }
    }, [editAdvisory]);

    const fetchCropAdvisory = ()=>{
      getCropRecommendations().then((res:any)=>{
        if(!res?.status||!res.data) {
            toast.error("Error fetching crop advisory")
        }
        else{
            setCropAdvisoryList(res.data)
        }

    })
   
  }
    useEffect(()=>{
      fetchCropAdvisory()
    },[])

  // to update the crop name in the list of select crops for advisory
  const handleCropTypeChange = (index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedCrops = [...selectedCrops];
    newSelectedCrops[index] = e.target.value;

    if (!e.target.value) return;

    setSelectedCrops(newSelectedCrops);
    setInputData((prevData) => ({
      ...prevData,
      crops: newSelectedCrops,
    }));
  };

  // remove the crop if one exist in the crop list for advisory
  const handleCropTypeRemove = (crop: string) => {
    const filteredCrops = selectedCrops.filter((item) => item !== crop);
    setSelectedCrops(filteredCrops);
    setInputData((prevData) => ({
      ...prevData,
      crops: filteredCrops.filter((item) => item !== ""),
    }));
  };

  const handleParamsMinChange = (e:ChangeEvent<HTMLInputElement>)=>{
    const tempAdvisoryParameters = advisoryParameters
    const paramName = e.target.name as AdvisoryParameterName;
    //tempAdvisoryParameters[paramName] will exist because of initialization done on handleButtonCLick
    const currentParam = tempAdvisoryParameters[paramName] as AdvisoryParameterRange
    currentParam.lowerLimit = Number(e.target.value)
    setAdvisoryParameters((prevParams) => ({
      ...prevParams,              
      [paramName]: currentParam, 
    }));    
  }


  const handleParamsMaxChange = (e:ChangeEvent<HTMLInputElement>)=>{
    const tempAdvisoryParameters = advisoryParameters
    const paramName = e.target.name as AdvisoryParameterName;
    //tempAdvisoryParameters[paramName] will exist because of initialization done on handleButtonCLick
    const currentParam = tempAdvisoryParameters[paramName] as AdvisoryParameterRange
    currentParam.upperLimit = Number(e.target.value)
    setAdvisoryParameters((prevParams) => ({
      ...prevParams,              
      [paramName]: currentParam, 
    }));
  }

  const validateCropAdvisory = () =>{
    let validated = true
    if(selectedCrops.length===0){
      toast.error("A crop should be selected")
      validated = false
    }
    if(!advisory || advisory.length===0){
      toast.error("No value entered for advisory")
      validated = false
    }
    if(Object.keys(advisoryParameters).length===0){
      toast.error("No parameters set")
      validated = false
    }
    Object.keys(advisoryParameters).forEach((parameter:string)=>{
      const currrentParameter  = advisoryParameters[parameter] as AdvisoryParameterRange
      const normalParamName = camelToFlat[parameter as keyof typeof camelToFlat]
      if(!currrentParameter.lowerLimit){
        toast.error(`Lower limit not set for${normalParamName}`)
        validated = false
      }
      if(!currrentParameter.upperLimit){
        toast.error(`Upper limit not set for${normalParamName}`)
        validated = false
      }
      if((currrentParameter.lowerLimit as number)>(currrentParameter.upperLimit as number)){
        toast.error(`Lower limit greater than upper limit for ${normalParamName}`)
        validated = false
      }
      if((currrentParameter.upperLimit as number)<(currrentParameter.lowerLimit as number)){
        toast.error(`Upper limit smaller than lower limit for ${normalParamName}`)
        validated = false
      }

    })
    return validated

  }
  const resetAdvisoryStates = ()=>{
    setSelectedCrops([])
    setAdvisory("")
    setSelectedValues([])
    setAdvisoryParameters({})
  }
  const handleAdvisorySubmit = async (e: any) => {
    e.preventDefault();
    if(validateCropAdvisory()){
      setUploading(true)
      const requestBody:AdvisoryType&{id?:string}  = {
        crops:selectedCrops as string[],
        parameters:advisoryParameters,
        advisory
      }
      if(!editAdvisory){
        postCropRecommendations(requestBody) 
        .then((res: any) => {
          if (res.status) {
            toast.success("Recommendation added successfully");
          } else {
            toast.error("Error adding the recommendation");
          }
        })
        .then(()=>fetchCropAdvisory())
        .finally(() => {
          setUploading(false)
          resetAdvisoryStates()
        });
      }else{
        requestBody.id = editAdvisory?.id
        editCropRecommendation(requestBody) 
      .then((res: any) => {
        if (res.status) {
          toast.success("Recommendation edited successfully");
        } else {
          toast.error("Error editing the recommendation");
        }
      })
      .then(()=>fetchCropAdvisory())
      .finally(() => {
        setUploading(false)
        resetAdvisoryStates()
      });
      }
    }
    
  };
  const handleButtonClick = (value: string) => {
    setSelectedValues(
      (prev) =>
        prev.includes(value)
          ? prev.filter((item) => item !== value) // Remove if already selected
          : [...prev, value] // Add if not selected
    );
    const tempAdvisoryPaarameters = advisoryParameters
    //make sure to initialize advisory parameters before rendering the range setters
    tempAdvisoryPaarameters[(flatToCamel[value as keyof typeof flatToCamel])] = {
      "lowerLimit":undefined,
      "upperLimit":undefined
    }
    setAdvisoryParameters(tempAdvisoryPaarameters)
  };


  return (
    <div>
      <Container>
        <div>
          <Form className="mt-4" onSubmit={handleOnSubmit}>
            <Form.Group as={Col} className="mb-3 d-flex align-items-center gap-2" controlId="formPlaintextName">
            <p className="font-weight-bold mb-0">Add Crops : </p>
              <Col xs={12} lg={4}>
                <Form.Control
                  type="text"
                  name="name"
                  value={inputData.name.toLowerCase()}
                  onChange={handleOnChange("name")}
                  autoComplete="off"
                />
              </Col>
              <Col xs={12} lg="auto">
                <Button
                  variant="primary"
                  type="submit"
                  className="bg-aepc-dark btn-lg m-3"
                  style={{ width: "150px", fontSize: "14px" }}
                  disabled={cropExist}
                >
                  Add Crops
                </Button>
              </Col>
            </Form.Group>
            {cropExist && <span className="text-danger fw-bold">Crops already exist</span>}
          </Form>
        </div>

        <div className="d-flex gap-2 align-items-center flex-wrap" style={{ marginTop: "2rem" }}>
          <p className="font-weight-bold mb-0">Existing Crops : </p>
          {cropList &&
            cropList.map((crop) => (
              <span
                className="badge rounded-pill px-4 py-2 text-muted text-uppercase"
                key={crop}
                style={{ color: "#0d6efd", fontWeight: "500", border: "2px solid #0d6efd" }}
              >
                {crop}
              </span>
            ))}
        </div>
        <hr className="my-4" />
        <div>
        <h1>Add Advisory</h1>
          <Form className="mt-4" onSubmit={(e) => handleAdvisorySubmit(e)}>
            <Row className="mb-4">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Select the crops</Form.Label>
              </Form.Group>
              {selectedCrops?.map((crop, index) => (
                <Col
                  xs={12}
                  lg={12}
                  key={index}
                  className="mb-3 border-primary bg-body"
                  style={{ display: " flex", gap: "10px" }}
                >
                  <Form.Group style={{ flex: 1, maxWidth: "200px" }}>
                    <Form.Label>Crop {index + 1}</Form.Label>
                    <Form.Select
                      className="coloured-select-toggle"
                      value={crop}
                      onChange={handleCropTypeChange(index)}
                      
                    >
                      <option 
                       value="">Crop Name</option>
                      {cropTypes.map((cropType, idx) => (
                        <option
                          key={idx}
                          value={cropType}
                          disabled={selectedCrops.includes(cropType)}
                        >
                          {cropType}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <img
                      src={MinusSign}
                      alt="remove"
                      style={{ width: "20px", marginTop: "28px", cursor: "pointer" }}
                      onClick={() => handleCropTypeRemove(crop)}
                    />               
                   
                </Col>
              ))}

                <Col
                  xs={12}
                  lg={12}
                  className="mb-3"
                  style={{ display: " flex", gap: "10px" }}
                >
                  <Form.Group style={{ flex: 1, maxWidth: "200px" }}>
                    <Form.Select
                      className="select-toggle bg-body border-primary"
                      value={""}
                      onChange={handleCropTypeChange(selectedCrops.length)}
                      style={{borderBottomWidth: "1px"}}
                    >
                      <option value="">Crop Name</option>
                      {cropTypes.map((cropType, idx) => (
                        <option
                          key={idx}
                          value={cropType}
                          disabled={selectedCrops.includes(cropType)}
                        >
                          {cropType}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>        
                   
                </Col>

            </Row>
            <div className="d-flex flex-column">
              <p className="fs-2" style={{ }}>Select Parameters</p>
            </div>

            <Form.Group>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-column">
                  <p className="text-muted" style={{ fontSize: "1rem" }}>
                    <span className="text-decoration-underline" style={{ fontWeight: 500, fontSize: "1.1rem" }}>System Parameters</span>
                  </p>
                  <div className="d-flex flex-wrap gap-4">
                    {systemParameters.map((sys) => (
                      <Button
                        key={sys}
                        variant={selectedValues.includes(sys) ? "primary" : "outline-primary"}
                        // variant="outline-primary"
                        className="px-4 py-2 btn-sm fw-bold"
                        onClick={() => handleButtonClick(sys)}
                      >
                        {sys}
                      </Button>
                    ))}
                  </div>
                </div>

                <hr className="my-4" />
                
                <div className="d-flex flex-column">
                  <p className="text-muted" style={{ fontSize: "1rem" }}>
                    <span className="text-decoration-underline"  style={{ fontWeight: 500, fontSize: "1.1rem" }}>
                      Environmental Parameters{" "}
                    </span>
                  </p>
                  <div className="d-flex flex-wrap gap-4">
                    {environmentalParameters.map((env) => (
                      <Button
                        key={env}
                        variant={selectedValues.includes(env) ? "primary" : "outline-primary"}
                        className="px-4 py-2 btn-sm fw-bold"
                        onClick={() => handleButtonClick(env)}
                      >
                        {env}
                      </Button>
                    ))}
                  </div>
                </div>

                <hr className="my-4" />
                
                <div className="d-flex flex-column">
                  <p className="text-muted" style={{ fontSize: "1rem" }}>
                    <span className="text-decoration-underline"  style={{ fontWeight: 500, fontSize: "1.1rem" }}> Weather Values </span>
                  </p>
                  <div className="d-flex flex-wrap gap-4">
                    {weatherValues.map((weather) => (
                      <Button
                        key={weather}
                        variant={selectedValues.includes(weather) ? "primary" : "outline-primary"}
                        // variant="outline-primary"
                        className="px-4 py-2 btn-sm fw-bold"
                        onClick={() => handleButtonClick(weather)}
                      >
                        {weather}
                      </Button>
                    ))}
                  </div>
                </div>

                <hr className="my-4" />
                
                <div className="d-flex flex-column">
                  {selectedValues && selectedValues.length > 0 ? (
                    <Form.Label className="mb-4" >
                      <span className="fs-2"> Set Range </span>
                      ( For the selected parameters )
                    </Form.Label>
                  ) : null}
                  <div className="d-flex gap-4 flex-wrap">
                    {selectedValues.map((value) => (
                      <div key={value} className="d-flex flex-column p-3 rounded">
                        <Form.Label>{value}</Form.Label>

                        <div className="d-flex gap-4">
                          <Form.Control
                            className="border-primary text-primary custom-placeholder"
                            type="number"
                            name={flatToCamel[value as keyof typeof flatToCamel]}
                            onChange={handleParamsMinChange}
                            autoComplete="off"
                            placeholder="Lower-limit"
                            value={(advisoryParameters[flatToCamel[value as keyof typeof flatToCamel]] as AdvisoryParameterRange).lowerLimit}
                          />

                          <Form.Control
                            className="border-primary text-primary custom-placeholder"
                            type="number"
                            name={flatToCamel[value as keyof typeof flatToCamel]}
                            onChange={handleParamsMaxChange}
                            autoComplete="off"
                            placeholder="Upper-limit"
                            value={(advisoryParameters[flatToCamel[value as keyof typeof flatToCamel]] as AdvisoryParameterRange).upperLimit}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <Form.Label className="mb-0 fs-2">
                  Set Advisory
                </Form.Label>
                <Form.Control
                  as="textarea" // Change type to textarea
                  name="advisory"
                  rows={3} // Specify the number of visible rows
                  autoComplete="off"
                  style={{
                    maxWidth: "80%",
                  }}
                  value={advisory}
                  onChange={e => setAdvisory(e.target.value)}
                />
              </div>
            </Form.Group>
            <div className="d-flex justify-content-end" style={{maxWidth:"80%"}}>
              <Button
                    variant="primary"
                    type="submit"
                    className="bg-aepc-dark btn-lg mt-3 mb-3"
                    style={{ width: "150px", fontSize: "14px" }}
                  >
                    {editAdvisory?"Edit":"Save"}
              </Button>
            </div>
          </Form>
        </div>
        <AdvisoryList cropAdvisoryList={cropAdvisoryList} setCropAdvisoryList={setCropAdvisoryList} setEditAdvisory={setEditAdvisory}></AdvisoryList>
      </Container>
    </div>
  );
};

export default AddCrops;
