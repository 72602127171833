import React, { useState, useContext, useEffect } from "react";
import { getOrganizationDetails } from "../../helpers/api";

const OrganizationContext = React.createContext({});

export const useOrganizationContext = () => {
  return useContext(OrganizationContext);
};


const OrganizationInfoProvider = (props: any) => {
  const [organization, setOrganization] = useState({});
  useEffect(()=>{
    const fetchOrganizationDetails =  async() => {
        const response = await getOrganizationDetails();
        const fetchedOrganization = response?.data?.Organizations[0] ? response?.data?.Organizations[0] : {}
        setOrganization(fetchedOrganization)
      
    };
    fetchOrganizationDetails()
  },[])
  return (
    <OrganizationContext.Provider value={{ organization, setOrganization }}>{props.children}</OrganizationContext.Provider>
  );
};

export default OrganizationInfoProvider;
