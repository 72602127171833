import React, { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiFolderPlus } from "react-icons/fi";
import FilePicker from "../../components/form/FilePicker";
import { Divider, GoBack, LoadingOverlay, PageTitle } from "../../components/shared";
import {
  addBeneficairy,
  getAllCrops,
  getSingleBeneficairyDetails,
  registerOrganization,
  updateBeneficiary,
  uploadFile,
} from "../../../helpers/api";
import { OrganizationDataType } from "../../ts/types";
import { toast } from "react-toastify";
import DocumentIcon from "../../../assets/icons/documentIcon.png";
import history from "../../../history";
import _ from "lodash";
import { InitialBeneficiaryData } from "./Benificiary";
import { BeneficiaryDataType } from "../../ts/types";
import crops from "../../../storage/crops/crops.json";
import landArea from "../../../storage/landArea/landArea.json";
import { useParams } from "react-router-dom";
import MinusSign from "../../../assets/icons/minus-red.svg";

const EditBeneficary = () => {
  const [inputData, setInputData] = useState<BeneficiaryDataType>(InitialBeneficiaryData);
  const [uploading, setUploading] = useState<boolean>(false);
  const [cropTypes, setCropTypes] = useState<string[]>([]);
  const [selectedCrops, setSelectedCrops] = useState<string[]>([""]);

  const { beneficiaryId, projectId } = useParams() as any;

  const handleOnChange = (inputKey: keyof BeneficiaryDataType) => (e: ChangeEvent<any>) => {
    const value = e.target.value;
    setInputData((prevData) => ({
      ...prevData,
      [inputKey]: value,
    }));
  };

  const handleAddCropType = () => {
    const addedCrops = [...selectedCrops, ""];
    const uniqueCrops = Array.from(new Set(addedCrops));

    setSelectedCrops(uniqueCrops);
  };

  const handleCropTypeChange = (index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedCrops = [...selectedCrops];
    newSelectedCrops[index] = e.target.value;
    setSelectedCrops(newSelectedCrops);
    setInputData((prevData) => ({
      ...prevData,
      crops: newSelectedCrops,
    }));
  };

  const handleCropTypeRemove = (crop: string) => {
    const filteredCrops = selectedCrops.filter((item) => item !== crop);
    setSelectedCrops(filteredCrops);
    setInputData((prevData) => ({
      ...prevData,
      crops: filteredCrops.filter((item) => item !== ""),
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);
    // setUploading(false);
    if (
      !inputData.projectId ||
      !inputData.phoneNumber ||
      !inputData.name ||
      !inputData.landArea ||
      !inputData.crops.length
    ) {
      toast.error("Please fill all the necessary fields");
      setUploading(false);
    } else {
      const payload = {
        id: inputData.id,
        phoneNumber: inputData.phoneNumber,
        name: inputData.name,
        landArea: inputData.landArea,
        landAreaUnit: inputData.landAreaUnit,
        crops: inputData.crops,
      };

      updateBeneficiary(payload)
        .then((res: any) => {
          if (res.status) {
            toast.success("Beneficiary updated successfully");
          } else {
            toast.error("Error updating the beneficiary");
          }
        })
        .finally(() => setUploading(false));
    }
  };

  const fetchBeneficiaryDetail = async () => {
    try {
      const response = await getSingleBeneficairyDetails(beneficiaryId);

      if (!response?.data) {
        history.push(`/project/${projectId}`);
      }

      setInputData(response?.data);
      setSelectedCrops([...response?.data.crops, ""]);
    } catch (err) {
      toast.error("Failed to fetch data!");
    }
  };

  useEffect(() => {
    fetchBeneficiaryDetail();

    const fetchData = async () => {
      const { data } = await getAllCrops();
      const cropNames = data.map((crop: { id: string; name: string }) => crop.name);
      setCropTypes(cropNames);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Container>
        <div className="page-header">
          <div className="d-flex p-3">
            <GoBack />
            <div className="flex-grow-1 text-center">
              <PageTitle title="Edit Beneficiary" />
            </div>
          </div>
          <Divider type="light" size="medium" />
        </div>
        <div>
          <Form className="mt-5" onSubmit={handleOnSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="2">
                Name
              </Form.Label>
              <Col xs={12} lg={4}>
                <Form.Control
                  type="text"
                  name="name"
                  value={inputData.name}
                  onChange={handleOnChange("name")}
                  autoComplete="off"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="2">
                Phone Number
              </Form.Label>
              <Col xs={12} lg={4}>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={inputData.phoneNumber}
                  onChange={handleOnChange("phoneNumber")}
                  autoComplete="off"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="2">
                Land Area
              </Form.Label>
              <Col xs={12} lg={4}>
                <Form.Control
                  type="text"
                  className="w-50 d-inline-block"
                  name="landArea"
                  value={inputData.landArea}
                  onChange={handleOnChange("landArea")}
                  autoComplete="off"
                />
                <Form.Select
                  className="select-toggle d-inline-block ml-2"
                  value={inputData.landAreaUnit}
                  style={{ marginLeft: "10px", height: "auto", width: "calc(50% - 10px)" }}
                  onChange={handleOnChange("landAreaUnit")}
                >
                  {landArea.map((areaUnit: string, idx: number) => (
                    <option key={idx} value={areaUnit}>
                      {areaUnit}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <Row className="mb-4">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Type of Crops</Form.Label>
              </Form.Group>
              {selectedCrops.map((crop, index) => (
                <Col
                  xs={12}
                  lg={12}
                  key={index}
                  className="mb-3"
                  style={{ display: " flex", gap: "10px" }}
                >
                  <Form.Group style={{ flex: 1, maxWidth: "200px" }}>
                    <Form.Label>Crop {index + 1}</Form.Label>
                    <Form.Select
                      className="select-toggle"
                      value={crop}
                      onChange={handleCropTypeChange(index)}
                    >
                      <option value="">Select Crops</option>
                      {cropTypes.map((cropType, idx) => (
                        <option
                          key={idx}
                          value={cropType}
                          disabled={selectedCrops.includes(cropType)}
                        >
                          {cropType}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {selectedCrops.length - 1 === index ? (
                    <Button
                      variant="outline-primary"
                      className="btn-lg w-min-200"
                      style={{
                        fontSize: "1rem",
                        borderRadius: "8px",
                        border: "3px solid",
                        height: "fit-content",
                        alignSelf: "end",
                      }}
                      onClick={handleAddCropType}
                    >
                      <b>Confirm and add more</b>
                    </Button>
                  ) : (
                    <img
                      src={MinusSign}
                      alt="remove"
                      style={{ width: "20px", marginTop: "28px", cursor: "pointer" }}
                      onClick={() => handleCropTypeRemove(crop)}
                    />
                  )}
                </Col>
              ))}
            </Row>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div className="d-flex" style={{ gap: 30 }}>
                <div className="d-flex pt-4" style={{ display: "flex", alignItems: "flex-start" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="bg-aepc-dark btn-lg w-min-200"
                    style={{ width: "250px", marginTop: "10px", fontSize: "18px" }}
                  >
                    <b> Update</b>
                  </Button>
                </div>
                <div
                  className="d-flex pt-4"
                  style={{ display: "flex", alignItems: "flex-start" }}
                ></div>
              </div>
            </div>
          </Form>
        </div>
        {uploading && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default EditBeneficary;
