import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { urls, BASE_URL as apiUrl } from "./urls";
// Services
import Response from "../service/response";
// Types
import { MethodType } from "../app/ts/types";

// Helpers
const getHeaders = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return headers as AxiosRequestConfig;
};

const makeRequest = async (
  url: string,
  method: MethodType,
  data: object | null,
  headers: object,
  responseType?: AxiosResponse["config"]["responseType"] // Optional parameter
): Promise<Response> => {
  let response: Response = {} as Response;

  try {
    let result: AxiosResponse = {} as AxiosResponse;

    // Handle post, put, patch & delete
    if (method === "POST" || method === "PUT" || method === "PATCH" || method === "DELETE") {
      result = await axios({
        method,
        url,
        data,
        headers,
        ...(responseType && { responseType }), // Include responseType if provided
      });
    }

    // Handle get
    if (method === "GET") {
      result = await axios({
        method,
        url,
        params: data,
        headers,
        ...(responseType && { responseType }), // Include responseType if provided
      });
    }

    response = new Response(true, result.data, "Success", "");
  } catch (error: any) {
    response = new Response(false, null, "Error", error);
    Promise.reject(response);
  }
  return response;
};

// Interceptors
axios.interceptors.request.use(function (config: AxiosRequestConfig) {
  const user = localStorage.getItem("user");
  let token = user && typeof user === "string" ? JSON.parse(user)?.token : null;

  if (!!token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + token,
    };
  }
  return config;
});

const interceptor = axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // reject if unauthorized
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    axios.interceptors.response.eject(interceptor);

    try {
      const currentRefToken = localStorage.getItem("refreshToken");
      if (!!currentRefToken) {
        const response = await refreshToken(currentRefToken);
        localStorage.setItem("token", response?.data?.access_token);
        localStorage.setItem("refreshToken", response?.data?.refresh_token);

        originalRequest.headers.Authorization = response?.data?.access_token;
        return await axios(originalRequest);
      } else {
        throw new Error("Refresh token does not exist");
      }
    } catch (err) {
      localStorage.removeItem("user");
      window.location.replace("/");
      return Promise.reject(err);
    }
  }
);

const refreshToken = async (token: string) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
  const data = { refreshToken: token };
  return axios({
    method: "POST",
    url: urls.refreshToken,
    data,
    headers,
  });
};

// login
export const login = async (email: string, password: string) => {
  const data = { email, password };
  return makeRequest(urls.login, "POST", data, getHeaders());
};

// file
export const uploadFile = async (fileData: any) => {
  return makeRequest(`${apiUrl}/upload-file`, "POST", fileData, getHeaders());
};

export const deleteFile = async (deleteData: any) => {
  const result = await makeRequest(
    `${apiUrl}/project/delete-document`,
    "PUT",
    deleteData,
    getHeaders()
  );
  return result;
};

// dashboard
export const dashboard = async () => {
  const result = await makeRequest(`${apiUrl}/project/dashboard`, "GET", null, getHeaders());
  const user = localStorage.getItem("user");
  let token = user && typeof user === "string" ? JSON.parse(user)?.token : null;
  return result;
};

// project
export const registerProject = async (data: object) => {
  return makeRequest(urls.project, "POST", data, getHeaders());
};

export const registerOrganization = async (data: object) => {
  return makeRequest(urls.organization, "POST", data, getHeaders());
};

export const getOrganizationDetail = async (organizationId: string) => {
  return makeRequest(`${urls.organization}/${organizationId}`, "GET", null, getHeaders());
};

export const getSubOrganizationDetail = async (subOrganizationId: string) => {
  return makeRequest(`${urls.subOrganization}/${subOrganizationId}`, "GET", null, getHeaders());
};

export const editOrganizationDetails = async (data: object) => {
  return makeRequest(urls.organization, "PUT", data, getHeaders());
};

export const editSubOrganizationDetails = async (data: object) => {
  return makeRequest(urls.subOrganization, "PUT", data, getHeaders());
};

export const registerSubOrganization = async (data: object) => {
  return makeRequest(urls.subOrganization, "POST", data, getHeaders());
};

export const getProjectByStatus = async (status: string) => {
  const url = `${apiUrl}/project/filter?status=${status}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const filterProjects = async (data: {
  status: string;
  region: string;
  district: string;
  meterStatus: string;
}) => {
  const url = `${apiUrl}/project/filter?status=${data.status}&region=${data.region}&district=${data.district}&meterStatus=${data.meterStatus}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const searchProjectByName = async (keyword: string, sort: string) => {
  const url = `${apiUrl}/project/search?query=${keyword}&sortBy=${sort}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const getProjectDetails = (projectId: string) => {
  const url = urls.getProjectDetails.replace(":projectId", projectId);
  return makeRequest(url, "GET", null, getHeaders());
};

export const getAllProjects = async () => {
  const url = `${apiUrl}/project/dashboard`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const getProjectsByPagination = async (offset: number) => {
  const url = `${apiUrl}/project/dashboard?offset=${offset}&limit=10`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const updateProject = async (data: any) => {
  const result = await makeRequest(`${apiUrl}/project/update`, "PUT", data, getHeaders());
  return result;
};

export const updateProjectStatus = async (data: any) => {
  const result = await makeRequest(`${apiUrl}/project/update-status`, "PUT", data, getHeaders());
  return result;
};

// meter
export const createMeter = async (data: object) => {
  return makeRequest(urls.meter, "POST", data, getHeaders());
};

export const fetchMeterDetailByProjectId = async (projectId: string) => {
  const url = `${apiUrl}/project/${projectId}/meter`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const fetchRecentOnsiteParams = async (meterId: string) => {
  const url = `${apiUrl}/meter/recent-data/${meterId}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const updateMeter = async (data: object) => {
  return makeRequest(urls.meter, "PUT", data, getHeaders());
};

export const getMeterById = async (meterId: string) => {
  const url = `${apiUrl}/meter/find/${meterId}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const getMeterFilterById = async (meterId: string) => {
  const url = `${apiUrl}/meter/filter/${meterId}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const createMeterFilter = async (data: object) => {
  const url = `${apiUrl}/meter/filter/`;
  return makeRequest(url, "POST", data, getHeaders());
};
export const updateMeterFilterData = async (data: object) => {
  const url = `${apiUrl}/meter/filter/`;
  return makeRequest(url, "PUT", data, getHeaders());
};

export const meterDetailsDaily = async (data: {
  filter: string;
  meterId: string;
  from: string;
  to: string;
}) => {
  const url = `${apiUrl}/meter/meter-data/graph/daily?filter=${data.filter}&meterId=${data.meterId}&from=${data.from}&to=${data.to}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const getMonthlyDetails = (filterOptions: {
  filter: string;
  meterId: string;
  from: string;
  to: string;
}) => {
  const url = urls.meterDetails.MONTHLY.replace(":filter", filterOptions.filter)
    .replace(":meterId", filterOptions.meterId)
    .replace(":from", filterOptions.from)
    .replace(":to", filterOptions.to);

  return makeRequest(url, "GET", null, getHeaders());
};

export const getYearlyDetails = (filterOptions: {
  filter: string;
  meterId: string;
  from: string;
  to: string;
}) => {
  const url = urls.meterDetails.YEARLY.replace(":filter", filterOptions.filter)
    .replace(":meterId", filterOptions.meterId)
    .replace(":from", filterOptions.from)
    .replace(":to", filterOptions.to);
  return makeRequest(url, "GET", null, getHeaders());
};

export const getMeterActivityStatus = (projectId: string) => {
  const url = urls.meterDetails.METER_ACTIVITY.replace(":projectId", projectId);
  return makeRequest(url, "GET", null, getHeaders());
};

export const enablePump = (meterId: string) => {
  const url = urls.meterDetails.ENABLE_PUMP;
  const body = { id: meterId };
  return makeRequest(url, "PUT", body, getHeaders());
};

export const disablePump = (meterId: string) => {
  const url = urls.meterDetails.DISABLE_PUMP;
  const body = { id: meterId };
  return makeRequest(url, "PUT", body, getHeaders());
};

// farmer
export const farmerInfo = async () => {
  const result = await makeRequest(`${apiUrl}/project`, "GET", null, getHeaders());
  return result;
};

export const getFarmerById = async (projectId: string) => {
  const result = await makeRequest(
    `${apiUrl}/project/find/${projectId}`,
    "GET",
    null,
    getHeaders()
  );
  return result;
};

// logs
export const getLogs = async (projectId: any) => {
  const result = await makeRequest(
    `${apiUrl}/logs/project/${projectId}`,
    "GET",
    null,
    getHeaders()
  );
  return result;
};

export const getLogsByPagination = async (offset: string, id: string) => {
  const result = await makeRequest(
    `${apiUrl}/logs/project/${id}?offset=${offset}&limit=10`,
    "GET",
    null,
    getHeaders()
  );
  return result;
};

// CSV download
export const downloadCsvReport = async (downloadOptions: Object) => {
  const result = await makeRequest(`${apiUrl}/csv`, "GET", downloadOptions, getHeaders());
  return result;
};

export const getOrganisationList = async () => {
  const result = await makeRequest(`${apiUrl}/organization`, "GET", null, getHeaders());
  return result;
};

// export const getSubOrganizations = async () => {
//   try {
//     // Fetch the organization list
//     const { data } = await makeRequest(`${apiUrl}/organization`, "GET", null, getHeaders());

//     const subOrganizations = data.flatMap((org: any) => org.subOrganizations);

//     return subOrganizations;
//   } catch (error) {
//     console.error("Failed to fetch organizations:", error);
//     return []; // Return an empty array in case of an error
//   }
// };

export const getSubOrganizations = async (organizationName: string) => {
  try {
    // Fetch the organization list
    const { data } = await makeRequest(`${apiUrl}/organization`, "GET", null, getHeaders());

    // Find the organization with the given name
    const organization = data.find((org: any) => org.name === organizationName);

    // If the organization is found, return its sub-organizations
    if (organization) {
      return organization.subOrganizations;
    }
    return [];
  } catch (error) {
    // console.error("Failed to fetch organizations:", error);
    return []; // Return an empty array in case of an error
  }
};

export const addBeneficairy = async (data: object) => {
  // add beneficiary
  return makeRequest(urls.benificiary, "POST", data, getHeaders());
};

export const getBeneficairyDetails = async (projectId: String) => {
  const url = `${apiUrl}/project/beneficaries?projectId=${projectId}`;
  // add beneficiary
  return makeRequest(url, "GET", null, getHeaders());
};

export const getSingleBeneficairyDetails = async (beneficiaryId: String) => {
  const url = `${apiUrl}/project/beneficiary/${beneficiaryId}`;
  // add beneficiary
  return makeRequest(url, "GET", null, getHeaders());
};

export const removeBeneficiary = async (data: object) => {
  const url = `${apiUrl}/project/beneficary/remove`;
  // add beneficiary
  return makeRequest(url, "PUT", data, getHeaders());
};

export const updateBeneficiary = async (data: object) => {
  const url = `${apiUrl}/project/beneficary/`;
  // add beneficiary
  return makeRequest(url, "PUT", data, getHeaders());
};

export const getMessageLogs = async (projectId: String) => {
  // get all message logs
  const url = `${apiUrl}/project/sms/${projectId}/all`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const scheduleSms = async (data: object) => {
  const url = `${apiUrl}/project/sms/schedule`;
  return makeRequest(url, "POST", data, getHeaders());
};

export const getWeatherData = async (lat: number, lon: number, altitude: number) => {
  let url = `${apiUrl}/project/weather?latitude=${lat}&longitude=${lon}`;
  if (altitude) url += `&altitude=${altitude}`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const getOrganizationDetails = async () => {
  const url = `${apiUrl}/organization/details`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const deleteSubOrganization = async (subOrganizationId: string) => {
  const url = `${apiUrl}/sub-organization/delete`;
  return makeRequest(url, "PUT", { subOrganizationId }, getHeaders());
};

export const deleteOrganization = async (organizationId: string) => {
  const url = `${apiUrl}/organization/delete`;
  return makeRequest(url, "PUT", { organizationId }, getHeaders());
};

export const getAllCrops = async () => {
  const url = `${apiUrl}/crops`;
  return makeRequest(url, "GET", null, getHeaders());
};

export const addCrops = async (data: object) => {
  const url = `${apiUrl}/crops`;
  return makeRequest(url, "POST", data, getHeaders());
};

export const downloadCsvFile = async (projectId: string) => {
  const url = `${apiUrl}/project/${projectId}/beneficiary/downloadCsv`;
  return makeRequest(url, "GET", null, getHeaders(), "blob");
};

export const downloadCsvFileFormat = async () => {
  const url = `${apiUrl}/project/beneficiary/downloadFormatForCsv`;
  return makeRequest(url, "GET", null, getHeaders(), "blob");
};

export const uploadCsvFile = async (fileData: any) => {
  const url = `${apiUrl}/project/beneficiary/bulkUpload`;
  return makeRequest(url, "POST", fileData, getHeaders());
};
export const postCropRecommendations = async (data: any) => {
  const url = urls.cropRecommendations;
  return makeRequest(url, "POST", data, getHeaders());
};
export const getCropRecommendations = async () => {
  const url = urls.cropRecommendations;
  return makeRequest(url, "GET", null, getHeaders());
};
export const deleteCropAdvisory = async (data: { id: string }) => {
  const url = urls.cropRecommendations;
  return makeRequest(url, "DELETE", data, getHeaders());
};
export const editCropRecommendation = async (data: any) => {
  const url = urls.cropRecommendations;
  return makeRequest(url, "PATCH", data, getHeaders());
};
export const getRecommendationsForCrops = async (projectId: string, weatherData: any) => {
  const url = `${apiUrl}/crops/recommendations/${projectId}?windSpeed=${weatherData.windSpeed}&humidity=${weatherData.relativeHumidity}&temperature=${weatherData.airTemperature}&rainfall=${weatherData.precipitationAmount}`;
  return makeRequest(url, "GET", null, getHeaders());
};
