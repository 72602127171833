import React, { useState, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";

interface AlternativeLink {
  label: string;
  link?: string;
}
interface Props {
  type?: string;
  label: string;
  link?: string;
  icon: JSX.Element;
  alternativeLink?: AlternativeLink[];
  children?: Props[];
}
const SidebarLink: React.FC<Props> = ({ icon, type, label, link, children, alternativeLink }) => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const history = useHistory();

  const handleAlternativeLinkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLink = alternativeLink?.find((alt) => alt.label === event.target.value);
    if (selectedLink && selectedLink.link) {
      history.push(selectedLink.link);
      setSelectedOption(selectedLink.label);
    }
  };

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!selectedOption) {
      event.preventDefault();
    }
  };

  const isLinkActive =
    link && (location.pathname === link || location.pathname.indexOf(link) !== -1);

  useEffect(() => {
    if (isLinkActive) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isLinkActive]);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setSelectedOption("Organisation");
    }
  }, [location.pathname]);

  if (type === "title") return <div className="sidebar-link--title">{label}</div>;

  if (type === "divider") {
    return <hr className="sidebar-link--divider" />;
  }

  if (label === "Organisation" && !children) {

    return (
      <div className="sibebar-link">
          {alternativeLink &&
            alternativeLink.map((alternative: any, index: number) => (
              <Link to={alternative.link} key={index} className="sidebar-link--item" />
            ))}

          {/* <Link to={link ? link : ""} onClick={handleLinkClick}> */}
          <Link to="#" onClick={handleLinkClick}>
            <span className="icon">
              {!!icon ? icon : <span className={isLinkActive ? "primary" : ""} />}
            </span>
            <Form.Select
              aria-label="Default select example"
              className="form-control organization-select text-muted"
              onChange={handleAlternativeLinkChange}
              value={selectedOption}
            >
              <option value="" hidden>{label}</option>
              {alternativeLink &&
                alternativeLink.map((alternative: any, index: number) => (
                  <option key={index} style={{ padding: "5px" }}>
                    {alternative.label}
                  </option>
                ))}
            </Form.Select>
          </Link>
        </div>
    );
  }

  if (!children) {
    return (
      <div className="sibebar-link">
        <Link to={link ? link : ""}>
          <span className="icon">
            {!!icon ? icon : <span className={isLinkActive ? "primary" : ""} />}
          </span>
          <span className="title text-muted">{label}</span>
        </Link>
      </div>
    );
  }

  return (
    <div className="sibebar-link">
      <div>
        <div className="icon">
          {!!icon ? icon : <span className={isLinkActive ? "primary" : ""} />}
        </div>
        <span className="title text-muted">{label}</span>
      </div>
      {children && (
        <div className="nested-list">
          <div>
            {children.map((childrenLink) => (
              <SidebarLink key={childrenLink && childrenLink.link} {...childrenLink} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarLink;
