import React, { ChangeEvent, MouseEventHandler, useEffect, useRef, useState } from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FiFolderPlus } from "react-icons/fi";
import FilePicker from "../../components/form/FilePicker";
import { LoadingOverlay } from "../../components/shared";
import {
  addBeneficairy,
  downloadCsvFileFormat,
  getAllCrops,
  registerOrganization,
  uploadCsvFile,
  uploadFile,
} from "../../../helpers/api";
import { OrganizationDataType } from "../../ts/types";
import { toast } from "react-toastify";
import DocumentIcon from "../../../assets/icons/documentIcon.png";
import history from "../../../history";
import _ from "lodash";
import { InitialBeneficiaryData } from "./Benificiary";
import { BeneficiaryDataType } from "../../ts/types";
import crops from "../../../storage/crops/crops.json";
import landArea from "../../../storage/landArea/landArea.json";
import { useParams } from "react-router-dom";
import MinusSign from "../../../assets/icons/minus-red.svg";

const AddBenificiary = () => {
  const [inputData, setInputData] = useState<BeneficiaryDataType>(InitialBeneficiaryData);
  const [uploading, setUploading] = useState<boolean>(false);
  const [cropTypes, setCropTypes] = useState<string[]>([]);
  const [selectedCrops, setSelectedCrops] = useState<string[]>([""]);
  const [csvFile, setCsvFile] = useState<{ [key: string]: string }>({});

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { projectId } = useParams() as any;

  const onFileSelect = (fileName: string) => async (file: File) => {
    const upload = async () => {
      const bodyFormData = new FormData();
      bodyFormData.append("name", "file");
      bodyFormData.append("file", file);

      return uploadFile(bodyFormData).then((res: any) => {
        if (res.status) {
          setInputData((prevData) => ({ ...prevData, [fileName]: res.data.url }));
          toast.success("File uploaded successfully");
        } else {
          toast.error("Error while uploading the file");
        }
      });
    };

    setUploading(true);
    await upload();
    setUploading(false);
  };

  useEffect(() => {
    inputData.projectId = projectId;

    const fetchData = async () => {
      const { data } = await getAllCrops();
      const cropNames = data.map((crop: { id: string; name: string }) => crop.name);
      setCropTypes(cropNames);
    };
    fetchData();
  }, [projectId]);

  const handleOnChange = (inputKey: keyof BeneficiaryDataType) => (e: ChangeEvent<any>) => {
    const value = e.target.value;
    setInputData((prevData) => ({
      ...prevData,
      [inputKey]: value,
    }));
  };

  const handleAddCropType = () => {
    const addedCrops = [...selectedCrops, ""];
    const uniqueCrops = Array.from(new Set(addedCrops));

    setSelectedCrops(uniqueCrops);
  };

  const handleCropTypeChange = (index: number) => (e: ChangeEvent<HTMLSelectElement>) => {
    const newSelectedCrops = [...selectedCrops];
    newSelectedCrops[index] = e.target.value;

    if (!e.target.value) return;

    setSelectedCrops(newSelectedCrops);
    setInputData((prevData) => ({
      ...prevData,
      crops: newSelectedCrops,
    }));
  };

  const handleCropTypeRemove = (crop: string) => {
    const filteredCrops = selectedCrops.filter((item) => item !== crop);
    setSelectedCrops(filteredCrops);
    setInputData((prevData) => ({
      ...prevData,
      crops: filteredCrops.filter((item) => item !== ""),
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploading(true);
    // setUploading(false);
    if (
      !inputData.projectId ||
      !inputData.phoneNumber ||
      !inputData.name ||
      !inputData.landArea ||
      !inputData.crops.length
    ) {
      toast.error("Please fill all the necessary fields");
      setUploading(false);
    } else {
      addBeneficairy(inputData)
        .then((res: any) => {
          if (res.status) {
            toast.success("Beneficiary added successfully");
            history.push(`/project/beneficiaries/${projectId}`);
          } else {
            toast.error("Error adding the beneficiary");
          }
        })
        .finally(() => setUploading(false));
    }
  };

  const handleDownloadCsvFileFormat = async () => {
    try {
      const response = await downloadCsvFileFormat();

      if (!response.status) {
        throw new Error("Failed to download CSV.");
      }

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "text/csv;charset=utf-8;" });

      // Create a temporary download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "sample.csv"; // File name
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger the input's click event
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fileName = "csvFile";

      // Prepare FormData for the file upload
      const bodyFormData = new FormData();
      bodyFormData.append("projectId", projectId); // Append the projectId
      bodyFormData.append("benificiaryCsv", file); // Append the file

      try {
        setUploading(true);
        // Call the uploadCsvFile API
        const res = await uploadCsvFile(bodyFormData);

        // Check for successful upload response
        if (res.status) {
          setCsvFile((prevData) => ({
            ...prevData,
            [fileName]: res.data.url,
          }));
          toast.success("File uploaded successfully");
          history.push(`/project/beneficiaries/${projectId}`);
        } else {
          toast.error("Invalid file or data format");
        }
      } catch (error) {
        console.error("Upload failed:", error);
        toast.error("An unexpected error occurred during upload");
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div>
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <h1>Add Beneficiary</h1>
          <div className="button-container gap-3 d-flex">
            <Button
              variant="outline-primary"
              style={{
                fontSize: "0.8rem",
                borderRadius: "8px",
                border: "3px solid",
                minWidth: "150px",
              }}
              onClick={handleDownloadCsvFileFormat}
            >
              <b>Download Sample </b>
            </Button>

            <Button
              variant="outline-primary"
              style={{
                fontSize: "0.8rem",
                borderRadius: "8px",
                border: "3px solid",
                minWidth: "150px",
              }}
              onClick={triggerFileInput}
            >
              <b>Upload CSV </b>
            </Button>

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }} // Hide the file input
              accept=".csv" // Limit the accepted file types (CSV only)
              onChange={(e) => handleFileChange(e)}
            />
          </div>
        </div>

        <div>
          <Form className="mt-5" onSubmit={handleOnSubmit}>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="2">
                Name
              </Form.Label>
              <Col xs={12} lg={4}>
                <Form.Control
                  type="text"
                  name="name"
                  value={inputData.name}
                  onChange={handleOnChange("name")}
                  autoComplete="off"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="2">
                Phone Number
              </Form.Label>
              <Col xs={12} lg={4}>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={inputData.phoneNumber}
                  onChange={handleOnChange("phoneNumber")}
                  autoComplete="off"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
              <Form.Label column sm="2">
                Land Area
              </Form.Label>
              <Col xs={12} lg={4}>
                <Form.Control
                  type="text"
                  className="w-50 d-inline-block"
                  name="landArea"
                  value={inputData.landArea}
                  onChange={handleOnChange("landArea")}
                  autoComplete="off"
                />
                <Form.Select
                  className="select-toggle d-inline-block ml-2"
                  value={inputData.landAreaUnit}
                  style={{ marginLeft: "10px", height: "auto", width: "calc(50% - 10px)" }}
                  onChange={handleOnChange("landAreaUnit")}
                >
                  {landArea.map((areaUnit: string, idx: number) => (
                    <option key={idx} value={areaUnit}>
                      {areaUnit}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>

            <Row className="mb-4">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Type of Crops</Form.Label>
              </Form.Group>
              {selectedCrops.map((crop, index) => (
                <Col
                  xs={12}
                  lg={12}
                  key={index}
                  className="mb-3"
                  style={{ display: " flex", gap: "10px" }}
                >
                  <Form.Group style={{ flex: 1, maxWidth: "200px" }}>
                    <Form.Label>Crop {index + 1}</Form.Label>
                    <Form.Select
                      className="select-toggle"
                      value={crop}
                      onChange={handleCropTypeChange(index)}
                    >
                      <option value="">Select Crops</option>
                      {cropTypes.map((cropType, idx) => (
                        <option
                          key={idx}
                          value={cropType}
                          disabled={selectedCrops.includes(cropType)}
                        >
                          {cropType}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  {selectedCrops.length - 1 === index ? (
                    <Button
                      variant="outline-primary"
                      className="btn-lg w-min-200"
                      style={{
                        fontSize: "1rem",
                        borderRadius: "8px",
                        border: "3px solid",
                        height: "fit-content",
                        alignSelf: "end",
                      }}
                      // style={{ fontSize: "0.8rem", borderRadius: "8px", border: "3px solid" }}
                      onClick={handleAddCropType}
                    >
                      <b>Confirm and add more</b>
                    </Button>
                  ) : (
                    <img
                      src={MinusSign}
                      alt="remove"
                      style={{ width: "20px", marginTop: "28px", cursor: "pointer" }}
                      onClick={() => handleCropTypeRemove(crop)}
                    />
                  )}
                </Col>
              ))}
            </Row>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <div className="d-flex" style={{ gap: 30 }}>
                <div className="d-flex pt-4" style={{ display: "flex", alignItems: "flex-start" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="bg-aepc-dark btn-lg w-min-200"
                    style={{ width: "250px", marginTop: "10px", fontSize: "18px" }}
                  >
                    <b> Register</b>
                  </Button>
                </div>
                <div
                  className="d-flex pt-4"
                  style={{ display: "flex", alignItems: "flex-start" }}
                ></div>
              </div>
            </div>
          </Form>
        </div>
        {uploading && <LoadingOverlay />}
      </Container>
    </div>
  );
};

export default AddBenificiary;
