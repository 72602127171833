import { useEffect, useState } from "react";
import { Button, Container, Row, Col, Table, ProgressBar } from "react-bootstrap";
import history from "../../../history";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

//Helpers
import { getMeterActivityStatus, getProjectDetails } from "../../../helpers/api";
import { formatTimeDuration, getCurrentGmtTime } from "../../../helpers/dateTime";

//Types
import { MeterActivityStatusProps } from "../../ts/types";

//Enums
import { Currency } from "../../ts/enums";

//Shared
import { LoadingOverlay } from "../../components/shared";

//Components
import GeneralInfo from "./components/GeneralInfo";
import FilesAndLog from "./components/FilesAndLog";

//Assets
import EditIcon from "../../../assets/icons/editIcon.png";
import MessageLogs from "./components/MessageLogs";
import { useUserInfoContext } from "../../context/UserInfoContext";
import  { useProjectDetailsContext } from "../../context/ProjectDetailsContext";

const Operational = () => {
  const { projectId } = useParams() as any;
  const {projectDetails, setProjectDetails} = useProjectDetailsContext()
  const [meterDetails, setMeterDetails] = useState({} as MeterActivityStatusProps);
  const [loading, setLoading] = useState(true);

  const { user }: any = useUserInfoContext();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const projectDetailsResponse = await getProjectDetails(projectId);
        const meterDetailResponse = await getMeterActivityStatus(projectId);

        if (!projectDetailsResponse || !meterDetailResponse) {
          throw new Error("No data in response");
        }
        setProjectDetails(projectDetailsResponse.data);
        setMeterDetails(meterDetailResponse.data);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    })();
  }, [projectId]);
  const handleCreateEditMeter = () => {
    if (meterDetails.id) {
      history.push(`/edit-meter/${projectId}`);
    } else {
      history.push(`/create-meter/${projectId}`);
    }
  };

  return (
    <div>
          {loading && <LoadingOverlay />}
          <GeneralInfo org={projectDetails?.projectOrg} />

          <p
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              color: "silver",
            }}
            id="Equipments"
          >
            Project Status
          </p>
          <Row style={{ width: "50%" }}>
            <Col md={10}>
              <p
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                }}
              >
                Operational
              </p>
            </Col>
            <Col>
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "silver",
                }}
              >
                4/4
              </p>
            </Col>
          </Row>
          <Row style={{ width: "50%", marginBottom: "15px" }}>
            <Col>
              <ProgressBar style={{ height: "10px" }} now={100} />
            </Col>
            <Col>
              <ProgressBar style={{ height: "10px" }} now={100} />
            </Col>
            <Col>
              <ProgressBar style={{ height: "10px" }} now={100} />
            </Col>
            <Col>
              <ProgressBar style={{ height: "10px" }} now={100} />
            </Col>
          </Row>
          <p
            style={{
              fontSize: "0.8rem",
            }}
          >
            The project is currently operational.
          </p>
          <hr
            style={{
              backgroundColor: "silver",
              border: "1px silver solid",
              margin: "2rem 0",
            }}
          />
          <p
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              color: "silver",
            }}
          >
            Equipment
          </p>
          <Container
            fluid
            style={{
              border: "1.5px solid silver",
              borderRadius: "10px",

              fontWeight: "normal",
            }}
          >
            <Row
              style={{
                borderRadius: "10px",
                backgroundColor: "rgb(230, 230, 230)",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Col md={7} xs={6} className="pt-2">
                <p>
                  <b>Project Details</b>
                </p>
              </Col>
              <Col md={5} xs={6}>
                <div className="d-flex justify-content-end">
                  {/* <Row>
                  <Col
                    md={7}
                    sm={12}
                    xs={12}
                    className="installation_editDetails p-xl-0"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  > */}
                  {user?.role === "SUPERADMIN" && (
                    <Button
                      variant="outline-primary"
                      style={{ fontSize: "0.8rem", backgroundColor: "white", marginRight: "5px" }}
                      onClick={() => history.push(`/edit-project/${projectId}/general-information`)}
                    >
                      <img src={EditIcon} className="generalInfo_Icons" />
                      <b style={{ color: "rgb(3, 146, 255)", marginRight: "5px" }}>
                        Edit Project Details
                      </b>
                    </Button>
                  )}
                  {/* </Col>

                  <Col
                    md={5}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginLeft: "-12px",
                    }}
                  > */}
                  {/* <span style={{ marginLeft: "10px" }}> */}
                  <Button
                    variant="primary"
                    style={{ fontSize: "0.8rem" }}
                    onClick={() => history.push(`/project-status/${projectId}/projectDetails`)}
                  >
                    <b> View Details</b>
                  </Button>
                  {/* </span> */}
                  {/* </Col>
                </Row> */}
                </div>
              </Col>
            </Row>
            <Table responsive>
              <thead className="farmerDetail__thead">
                <tr
                  style={{
                    borderBottom: "1.5px solid silver",
                    fontWeight: "normal",
                  }}
                >
                  <th>Head</th>
                  <th> Daily Discharge</th>
                  <th>Total Solar Capacity</th>
                  <th>Pump Summary</th>
                  <th>Total Cost</th>
                  <th>Subsidy</th>
                  <th>User Contribution</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    borderBottom: "1.5px solid white",
                    fontWeight: "normal",
                  }}
                >
                  <td> {projectDetails?.waterSourceDetails?.head || "N/A"}</td>
                  <td>{projectDetails?.waterSourceDetails?.discharge || "N/A"} L/Day</td>
                  <td>{projectDetails?.solarArrayDetails?.totalCapacity || "N/A"} Wp</td>
                  <td>{projectDetails?.pumpDetails?.summary || "N/A"} </td>
                  <td>
                    {Currency.DEFAULT}
                    {projectDetails?.totalCost || "N/A"}
                  </td>
                  <td>
                    {Currency.DEFAULT}
                    {projectDetails?.totalSubsidy || "N/A"}
                  </td>
                  <td>
                    {Currency.DEFAULT}
                    {projectDetails?.userContribution || "N/A"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
          <Container
            fluid
            className="mt-5"
            style={{
              border: "1.5px solid silver",
              borderRadius: "10px",
              fontWeight: "normal",
            }}
          >
            <Row
              style={{
                borderRadius: "10px",
                backgroundColor: "rgb(230, 230, 230)",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Col md={7} xs={6} className="pt-2">
                <p>
                  <b>Meter Details</b>
                </p>
              </Col>
              <Col md={5} xs={6}>
                {/* <Row>
                  <Col
                    xl={8}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="installation_editDetails p-xl-0"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  > */}
                <div className="d-flex justify-content-end">
                  {user?.role === "SUPERADMIN" && (
                    <>
                      <Button
                        variant="outline-primary"
                        style={{ fontSize: "0.8rem", backgroundColor: "white", marginRight: "5px" }}
                        onClick={() => history.push(`/configure-meter/${projectId}`)}
                      >
                        <img src={EditIcon} className="generalInfo_Icons" />
                        <b style={{ color: "rgb(3, 146, 255)" }}>Configure Meter</b>
                      </Button>
                      <Button
                        variant="outline-primary"
                        style={{ fontSize: "0.8rem", backgroundColor: "white", marginRight: "5px" }}
                        onClick={handleCreateEditMeter}
                      >
                        <img src={EditIcon} className="generalInfo_Icons" />
                        <b style={{ color: "rgb(3, 146, 255)" }}>Edit Meter</b>
                      </Button>
                    </>
                  )}
                  {/* </Col>

                  <Col
                    xl={4}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginLeft: "-17px",
                    }} */}
                  {/* > */}
                  {/* <span style={{ backgroundColor: "white", marginLeft: "10px" }}>
                      {" "} */}
                  <Button
                    variant="primary"
                    style={{ fontSize: "0.8rem", marginRight: "5px" }}
                    onClick={() => history.push(`/meter-details/${projectId}`)}
                  >
                    <b> View Meter</b>
                  </Button>
                  {/* </span> */}
                </div>
                {/* </Col>
                </Row> */}
              </Col>
            </Row>
            <Table responsive>
              <thead className="farmerDetail__thead">
                <tr
                  style={{
                    borderBottom: "1.5px solid silver",
                    fontWeight: "normal",
                  }}
                >
                  <th>Meter Number</th>

                  <th>Meter ID</th>

                  <th>Meter Status</th>

                  <th>Last Active</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    borderBottom: "1.5px solid white",
                    fontWeight: "normal",
                  }}
                >
                  <td>{meterDetails.projectMeter?.phoneNumber || "N/A"}</td>
                  <td>{meterDetails.projectMeter?.hardwareId || "N/A"}</td>
                  <td>{meterDetails.projectMeter?.status === "Y" ? "Active" : "Inactive"}</td>
                  <td>
                    {formatTimeDuration(
                      getCurrentGmtTime() -
                        new Date(meterDetails.projectMeter?.meterData[0]?.receivedAt).getTime()
                    ) || "N/A"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>

          <MessageLogs projectIdNumber={projectId} />

          <FilesAndLog />
    </div>
  );
};

export default Operational;
