/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import classNames from "classnames";
// Views
import PageNotFound from "../views/session/PageNotFound";
// Navigations
import { RootRoutes } from "../navigations";

// Contexts
import {  useLayout } from "../context/LayoutContext";
// Utils
// Components
import Header from "./components/Header";
import { PrivateRoute } from "../components/routes/Route";
import Sidebar from "./components/sidebar/Sidebar";
import ContentWrapper from "./components/ContentWrapper";
import Footer from "./components/Footer";
import OrganizationInfoProvider from "../context/OrganizationContext";
import ProjectDetailsInfoProvider from "../context/ProjectDetailsContext";

const Layout: React.FC = () => {
  const { state } = useLayout();


  return (
    <div className="app">
      <OrganizationInfoProvider>
        <ProjectDetailsInfoProvider>
        <Sidebar />

        <div className={classNames("main", { "horizontal-fluid": state.isHorizontalFluid })}>
          <Header />

          <ContentWrapper>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              {RootRoutes.map((each, idx) => {
                const { path, component, ...rest } = each;
                return (
                  <PrivateRoute
                    key={`route-${idx}`}
                    exact
                    path={path}
                    component={component}
                    {...rest}
                  />
                );
              })}
              <Route component={PageNotFound} />
            </Switch>
          </ContentWrapper>

          <Footer />
        </div>
        </ProjectDetailsInfoProvider>
      </OrganizationInfoProvider>
    </div>
  );
};

export default Layout;
