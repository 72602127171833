import { Dispatch, Key, SetStateAction, useState } from "react"
import { deleteCropAdvisory, getCropRecommendations } from "../../../../helpers/api"
import { toast } from "react-toastify"
import { Button, Table } from "react-bootstrap"
import { AdvisoryParameter, AdvisoryParameterRange, AdvisoryType } from "../../../ts/types"
import {BsFillPencilFill, BsFillTrashFill} from 'react-icons/bs'
import { camelToFlat } from "../../../constants/crop"
import CustomModal from "../../shared/CustomModal"

type AdvisoryListProps={
    cropAdvisoryList:((AdvisoryType&{id:string})[])|[];
    setCropAdvisoryList:Dispatch<SetStateAction<(AdvisoryType & { id: string; })[]|[]>>
    setEditAdvisory:Dispatch<SetStateAction<any>>
}
const AdvisoryList : React.FC<AdvisoryListProps>= ({cropAdvisoryList,setCropAdvisoryList,setEditAdvisory})=>{
    const [advisoryToDelete,setAdvisoryToDelete] = useState<string|null>(null)

    const handleAdvisoryDelete = async (id:string|null) => {
        if(!id) return
        try {
            await deleteCropAdvisory({ id })
            const res = await getCropRecommendations()

            if (!res?.status || !res.data) {
                toast.error("Error fetching updated crop advisory list")
            } else {
                setCropAdvisoryList(res.data)
                setAdvisoryToDelete(null)
            }

            toast.success("Advisory deleted successfully")
        } catch (error) {
            toast.error("Error deleting advisory")
        }
      };

    const NamesFromParameters = (parameters:AdvisoryParameter)=>{
        return <>
            {Object.keys(parameters).map((parameter,i)=>(
                <li key={i}> {camelToFlat[parameter as keyof typeof camelToFlat]}</li>
            ))}
        </>
    }
    const RangeFromParemeter = (parameters:AdvisoryParameter)=>{
        return <>
            {Object.keys(parameters).map((paramName:string, i:Key) => (
                    <li key={i}>
                        {`${(parameters[paramName] as AdvisoryParameterRange).lowerLimit} to  ${(parameters[paramName] as AdvisoryParameterRange).upperLimit}`}
                    </li>
                ))}
        </>
    }
    const CropsNameList = (crops:string[])=>{
        return <>
            {crops.map((crop:string, i:Key) => (
                    <li key={i}>
                        {crop}
                    </li>
                ))}
        </>
    }
    const DeleteAlertContent = () =>{
        return(
            <div>
                Are you sure you want to delete this advisory?
                <Button className="m-1" onClick={()=>{handleAdvisoryDelete(advisoryToDelete)}}>Yes</Button>
                <Button className="m-1" onClick={()=>{setAdvisoryToDelete(null)}}>No</Button>
            </div>
        )
    }
    return (
    <div>
        <div className="mb-3 d-flex gap-2 align-items-center">
            <span className="fs-2 mr-3">Advisory Library</span> 
        </div>
        
        {cropAdvisoryList.length!==0&&(<Table bordered hover>
            <thead className="fst-normal">
                <tr>
                <th>Crops</th>
                <th>Parameters</th>
                <th>Range</th>
                <th>Advices</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {cropAdvisoryList.map((advisory) => (
                <tr key={advisory.id}>
                    <td>
                        <ul>
                            {CropsNameList(advisory.crops)}
                        </ul>
                        
                    </td>
                    <td>
                        <ul>
                            {NamesFromParameters(advisory.parameters)}
                        </ul>
                    </td>
                    <td>
                        <ul>
                            {RangeFromParemeter(advisory.parameters)}
                        </ul>
                    </td>
                    <td>{advisory.advisory}</td>
                    <td>
                        <Button className="m-1" variant="primary">
                            <BsFillPencilFill name={advisory.id} onClick={() => setEditAdvisory(advisory)} className="cursor-pointer"/>
                        </Button>
                        <Button  className="m-1" variant="danger">
                            <BsFillTrashFill name={advisory.id} onClick={() => setAdvisoryToDelete(advisory.id)}  className="cursor-pointer"/>
                        </Button>
                        
                    </td>
                </tr>
            ))}

            </tbody>
        </Table>)}
        {advisoryToDelete&&
            <CustomModal hideAlert={()=>{setAdvisoryToDelete(null)}}>
                <DeleteAlertContent />
            </CustomModal>}
    </div>
    )

}
export default AdvisoryList