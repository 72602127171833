export const systemParameters = [
    "Solar Current",
    "Solar Voltage",
    "Head",
    "Power",
    "Run Time",
    "Ac Voltage",
    "Ac Current",
    "Flow",
    "Discharge",
  ];

export const environmentalParameters = [
    "EC Temperature",
    "EC Humidity",
    "EC Sensor Temperature",
    "EC Sensor Humidity",
    "EC Sensor Moisture",
    "EC Conductivity",
  ];

export const weatherValues = ["Rainfall", "Temperature", "Humidity", "Wind Speed"];


//Make uste these values are up to date the system will not work properly otherwise
export const flatToCamel = {
  "EC Temperature":"ecTemperature",
  "EC Humidity":"ecHumidity",
  "EC Sensor Temperature":"ecSensorTemperature",
  "EC Sensor Humidity":"ecSensorHumidity",
  "EC Sensor Moisture":"ecSensorMoisture",
  "EC Conductivity":"ecConductivity",
  "Solar Voltage":"solarVoltage",
  "Solar Current":"solarCurrent",
  "Head":"head",
  "Power":"power",
  "Runtime":"runTime",
  "Ac Voltage":"acVoltage",
  "Ac Current":"acCurrent",
  "Flow":"flow",
  "Discharge":"discharge",
  "Rainfall":"rainfall",
  "Temperature":"temperature",
  "Humidity":"humidity",
  "Wind Speed":"windSpeed",
}
export const camelToFlat = {
 "ecTemperature": "EC Temperature",
  "ecHumidity":"EC Humidity",
  "ecSensorTemperature":"EC Sensor Temperature",
  "ecSensorHumidity":"EC Sensor Humidity",
  "ecSensorMoisture":"EC Sensor Moisture",
  "ecConductivity":"EC Conductivity",
  "solarVoltage":"Solar Voltage",
  "solarCurrent":"Solar Current",
  "head":"Head",
  "power":"Power",
  "runTime":"Runtime",
  "acVoltage":"Ac Voltage",
  "acCurrent":"Ac Current",
  "flow":"Flow",
  "discharge":"Discharge",
  "rainfall":"Rainfall",
  "temperature":"Temperature",
  "humidity":"Humidity",
  "windSpeed":"Wind Speed",
}

